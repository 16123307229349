import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    // State to hold the authentication token
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [userInfo, setUserInfo] = useState({});

    // A function that should be called any time someone wants to modify the current
    // auth token.
    const setAuthToken = useCallback((token) => {
        if (!token) {
            clearAuthToken();
            return;
        }

        try {
            const user = jwtDecode(token);
            localStorage.setItem('token', token);

            setToken(token);
            setUserInfo({
                orgId: user.orgId,
                userId: user.userId,
                username: user.username,
                role: user.role,
            });
        } catch (e) {
            console.error('Error setting a new token:', token, e);
            clearAuthToken();
        }
    }, []);

    const clearAuthToken = () => {
        localStorage.removeItem('token');
        setUserInfo({});
        setToken(undefined);
    };

    useEffect(() => {
        setAuthToken(localStorage.getItem('token'));
    }, [setAuthToken]);

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            token,
            userInfo,
            isAdmin: token && userInfo?.role === 'ADMIN',
            setAuthToken,
        }),
        [token, userInfo, setAuthToken]
    );

    // Provide the authentication context to the children components
    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;
