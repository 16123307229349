import { createContext, useContext, useMemo } from 'react';
import { useHttpClient } from './HttpClientProvider';
import { useQuery } from '@tanstack/react-query';
import { ORGANIZATION_OPTION_QUERY_KEY } from '../shared/utils/queryConstants';

const emptyResult = {};

const OrganizationOptionsContext = createContext();

const OrganizationOptionsProvider = ({ children }) => {
    const { data: organizationOptions = emptyResult } = useGetOrganizationOptions();

    const contextValue = useMemo(
        () => ({
            currency: { sign: '₾' },
            ...organizationOptions,
        }),
        [organizationOptions]
    );

    return (
        <OrganizationOptionsContext.Provider value={contextValue}>
            {children}
        </OrganizationOptionsContext.Provider>
    );
};

export const useOrgOptions = () => {
    return useContext(OrganizationOptionsContext);
};

export default OrganizationOptionsProvider;

function useGetOrganizationOptions() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [ORGANIZATION_OPTION_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get('/organization/options').then((result) => {
                if (result.status === 200) {
                    return result.data;
                }
            });
        },
        refetchInterval: 60 * 60 * 1000,
    });
}
