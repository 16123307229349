import { Backdrop, CircularProgress, TextField } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import '../RentalSessionPopUps.css';
import './RentalSessionEditPopUp.css';
import { useAuth } from '../../../provider/AuthProvider';
import { useRentalCategories } from '../../../provider/RentalSessionsWrapperProvider';

function RentalSessionEditPopup({ session, onOpenStateChange, onEdit, onError }) {
    const [isFormEdited, setIsFormEdited] = useState(false);
    const [editForm, setEditForm] = useState({
        startTimestamp: getHHMM(session.startTimestamp),
        rentalTypes: session.rentalTypes.map((rentalType) => rentalType._id),
        message: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const { isAdmin } = useAuth();
    const { categoriesMap } = useRentalCategories();

    const rentalTypes = useMemo(() => {
        const category = categoriesMap[session?.rentalCategoryId];

        return category ? category.rentalTypes : [];
    }, [categoriesMap, session]);

    const handleCancel = useCallback(() => {
        if (!isLoading) {
            onOpenStateChange(false);
        }
    }, [isLoading, onOpenStateChange]);

    const handleRentalTypeChange = useCallback(
        (event) => {
            setEditForm((curState) => {
                return { ...curState, rentalTypes: event?.value ? [event.value] : [] };
            });
        },
        [setEditForm]
    );

    const handleTimeAndNoteChange = useCallback(
        (event) => {
            setEditForm((curState) => {
                return { ...curState, [event.target.name]: event.target.value };
            });
        },
        [setEditForm]
    );

    const rentalTypeSelectOptions = useMemo(() => {
        return rentalTypes.map((type) => {
            return { value: type._id, label: type.name };
        });
    }, [rentalTypes]);

    const getRentalTypesDefaultValue = useCallback(() => {
        return session.rentalTypes.map((type) => {
            return { value: type._id, label: type.name };
        });
    }, [session]);

    const handleSave = useCallback(async () => {
        const timeInMs =
            Number(editForm.startTimestamp.split(':')[0]) * 60 * 60 * 1000 +
            Number(editForm.startTimestamp.split(':')[1]) * 60 * 1000;
        const dayTimestamp = new Date(
            new Date(parseFloat(session.startTimestamp)).toDateString()
        ).valueOf();

        const newStartTimestamp = (parseInt(dayTimestamp) + timeInMs).toString();

        const rentalType = rentalTypes.filter((type) => type._id === editForm.rentalTypes[0]);

        if (
            (newStartTimestamp > session.startTimestamp && !isAdmin) ||
            newStartTimestamp > Date.now()
        ) {
            onError(
                isAdmin
                    ? "Time can't be set to the future"
                    : "Time can't be set to later than original"
            );
        } else {
            setIsLoading(true);
            if (await onEdit(newStartTimestamp, rentalType, editForm.message)) {
                handleCancel();
            }
            setIsLoading(false);
        }
    }, [editForm, session, handleCancel, onError, onEdit, rentalTypes, isAdmin]);

    useEffect(() => {
        if (
            editForm.startTimestamp !== getHHMM(session.startTimestamp) ||
            editForm.rentalTypes[0] !== session.rentalTypes[0]
        ) {
            setIsFormEdited(true);
        } else {
            setIsFormEdited(false);
        }
    }, [editForm, session]);

    const customStyles = useMemo(
        () => ({
            option: (base, { isFocused }) => {
                return {
                    ...base,
                    backgroundColor: isFocused ? 'var(--primary-color)' : 'var(--secondary-blue)',
                };
            },
            menu: (base) => {
                return {
                    ...base,
                    backgroundColor: 'var(--secondary-blue)',
                };
            },
            control: (base) => {
                return {
                    ...base,
                    backgroundColor: 'transparent',
                    width: '100%',
                    border: 'none',
                    boxShadow: 'none',
                };
            },
            container: (base) => {
                return {
                    ...base,
                    borderColor: 'transperent',
                    borderBottom: '1px solid rgb(160, 149, 149)',
                };
            },
        }),
        []
    );

    return (
        <>
            <div className="popUp-wrapper">
                <h3 className="sessionEdit-header">{session.rentalName}</h3>
                <div>
                    <form action="" className="sessionEdit-body">
                        <div className="sessionEdit-item">
                            <label htmlFor="">Start Time: </label>
                            <input
                                className="SessionEdit-timeInput"
                                name="startTimestamp"
                                id="startTimestamp"
                                type={'time'}
                                onChange={handleTimeAndNoteChange}
                                value={editForm.startTimestamp}
                            />
                        </div>

                        {rentalTypes && rentalTypes.length ? (
                            <div className="sessionEdit-rentalType sessionEdit-item">
                                <label htmlFor="rentalType">Rental Type:</label>
                                <div className="sessionEdit-inputClass">
                                    <Select
                                        defaultValue={getRentalTypesDefaultValue()}
                                        options={rentalTypeSelectOptions}
                                        isClearable
                                        placeholder="Select type..."
                                        name="rentalTypes"
                                        id="rentalTypes"
                                        onChange={handleRentalTypeChange}
                                        classNamePrefix="react-select"
                                        className="inputWindows"
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                        ) : null}
                        <div className="sessionEdit-item sessionEdit-note">
                            <TextField
                                id="message"
                                label="Add Note"
                                variant="standard"
                                name="message"
                                value={editForm.message}
                                onChange={handleTimeAndNoteChange}
                                InputLabelProps={{ shrink: true }}
                                className="sessionEdit-noteIput"
                            />
                        </div>
                    </form>
                </div>
                <div className="sessionPopUp-actions">
                    <button
                        className="popUp-button cancel"
                        onClick={handleCancel}
                        disabled={isLoading}>
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        disabled={!isFormEdited || isLoading}
                        className="popUp-button start">
                        {isLoading ? <CircularProgress className="circularProgress" /> : null}
                        Save
                    </button>
                </div>
            </div>
            <Backdrop className="alert-backdrop" open={true} onClick={handleCancel} />
        </>
    );
}

export default RentalSessionEditPopup;

const getHHMM = (timestamp) => {
    return new Date(parseFloat(timestamp)).toTimeString().split(' ')[0].substring(5, -1);
};
