import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useOrgOptions } from '../../provider/OrganizationOptionsProvider';
import { IconButton, Popover } from '@mui/material';
import { CalculateOutlined, Close } from '@mui/icons-material';
import './MoneyInputOption.css';

const clearValue = { value: '0', label: '0:00' };

const TimeInput = ({ disabled, onChange, moneyInputOptionConfig }) => {
    const [timeInputValue, setTimeInputValue] = useState({ ...clearValue });
    const [moneyInputValue, setMoneyInputValue] = useState(
        moneyInputOptionConfig?.totalPrice ? parseFloat(moneyInputOptionConfig.totalPrice) : 0
    );

    useEffect(() => {
        setMoneyInputValue(
            moneyInputOptionConfig?.totalPrice ? parseFloat(moneyInputOptionConfig.totalPrice) : 0
        );
    }, [moneyInputOptionConfig?.totalPrice]);

    const setDuration = useCallback(
        (value) => {
            setTimeInputValue(value);
            onChange(Math.max(0, getMinutesFromHHMM(value.value)));
        },
        [onChange]
    );

    const handleMoneyInputChange = useCallback(
        (value) => {
            setMoneyInputValue(value);
            const minutesForPrice = moneyInputOptionConfig.onChange(value);
            setDuration({
                value: minutesForPrice,
                label: getHHMMFromMinutes(minutesForPrice),
            });
        },
        [moneyInputOptionConfig, setDuration]
    );

    const handleChange = useCallback(
        (value, actionType) => {
            if (actionType.action === 'clear') {
                setDuration({ ...clearValue });
            }

            if (value) {
                setDuration(value);
            }
        },
        [setDuration]
    );

    const timeOptions = useMemo(
        () => [
            { value: '30', label: '0:30' },
            { value: '60', label: '1:00' },
            { value: '120', label: '2:00' },
            { value: '180', label: '3:00' },
        ],
        []
    );

    const customStyles = useMemo(
        () => ({
            option: (base, { isFocused }) => {
                return {
                    ...base,
                    backgroundColor: isFocused ? 'var(--primary-color)' : 'var(--secondary-blue)',
                    color: 'white',
                };
            },
            menu: (base) => {
                return {
                    ...base,
                    backgroundColor: 'var(--secondary-blue)',
                };
            },
            control: (base) => {
                return {
                    ...base,
                    backgroundColor: 'transparent',
                    width: '100%',
                    border: 'none',
                    boxShadow: 'none',
                };
            },
            container: (base, { isDisabled }) => {
                return {
                    ...base,
                    borderColor: 'transperent',
                    borderBottom: '1px solid rgb(160, 149, 149)',
                    opacity: isDisabled ? '0.5' : '1',
                };
            },
            indicators: (base) => {
                return {
                    ...base,
                    cursor: 'pointer',
                };
            },
        }),
        []
    );

    return (
        <div className="sessionSetup-timeInputs">
            {moneyInputOptionConfig && moneyInputOptionConfig.active ? (
                <MoneyInputOption value={moneyInputValue} onChange={handleMoneyInputChange} />
            ) : null}
            <CreatableSelect
                isClearable={timeInputValue.value > 0}
                blurInputOnSelect={true}
                options={timeOptions}
                type="text"
                id="timeInput"
                className="inputWindows"
                onChange={handleChange}
                placeholder="Select time..."
                classNamePrefix="react-select"
                isDisabled={disabled}
                styles={customStyles}
                value={timeInputValue}
            />
        </div>
    );
};

export default TimeInput;

function MoneyInputOption({ value, onChange }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [curValue, setCurValue] = useState(value);
    const receivedMoneyInput = useRef(null);
    const { currency } = useOrgOptions();

    const open = useMemo(() => {
        return Boolean(anchorEl);
    }, [anchorEl]);
    const id = useMemo(() => {
        return open ? 'simple-popover' : undefined;
    }, [open]);

    const handleModifyAmountChange = useCallback(
        (e) => {
            const newValue = e.target.value?.replace(/^0+/, '');
            onChange(newValue ? newValue : 0);
            setCurValue(newValue ? newValue : 0);
        },
        [onChange]
    );

    const handleModifyAmountKeyDown = useCallback((e) => {
        if (e.key === '-' || e.key === 'e') {
            e.preventDefault();
        }
    }, []);

    const handleOpen = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget);
            setCurValue(value);
            setTimeout(() => receivedMoneyInput.current.focus(), 10);
        },
        [value]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    return (
        <div className="moneyInputOptionWrapper">
            <IconButton
                aria-describedby={id}
                variant="contained"
                onClick={handleOpen}
                className="moneyInputOptionButton">
                <CalculateOutlined className="calculatorIcon" />
            </IconButton>
            <Popover
                id={id}
                sx={{
                    zIndex: '10000',
                }}
                className="moneyInputOptionPopover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <div className="moneyInputOptionPopup">
                    <div className="closeIconRow">
                        <Close onClick={handleClose} className="closeIcon" />
                    </div>
                    <div className="moneyInputOptionItem">
                        <label htmlFor="amountReceived">Amount Received:</label>
                        <label
                            htmlFor="amountReceived"
                            className="amountReceivedLabel"
                            currency-sign={currency.sign}>
                            <input
                                ref={receivedMoneyInput}
                                className="amountReceivedInput"
                                type="number"
                                id="amountReceived"
                                value={curValue}
                                placeholder="0"
                                onInput={handleModifyAmountChange}
                                onKeyDown={handleModifyAmountKeyDown}
                                autoFocus
                            />
                        </label>
                    </div>
                </div>
            </Popover>
        </div>
    );
}

const getMinutesFromHHMM = (value) => {
    const [str1, str2] = value.toString().split(':');

    const val1 = Number(str1);
    const val2 = Number(str2);

    if (!isNaN(val1) && isNaN(val2)) {
        return val1;
    }

    if (!isNaN(val1) && !isNaN(val2)) {
        return val1 * 60 + val2;
    }

    return 0;
};

const getHHMMFromMinutes = (value) => {
    value = parseInt(value);
    let minutes = value % 60;
    let hours = ((value - minutes) / 60).toString();

    minutes = minutes < 10 ? `0${minutes}` : minutes.toString();

    return hours + ':' + minutes;
};
