import { Backdrop } from '@mui/material';
import './Alert.css';
import { useCallback } from 'react';

function Alert({ message, setMessage }) {
    const handleClose = useCallback(() => {
        setMessage('');
    }, [setMessage]);

    return message?.length ? (
        <>
            <Backdrop className="alert-backdrop" onClick={handleClose} open={true} />
            <div className="alert-wrapper">
                <div onClick={handleClose} className="alert-close">
                    X
                </div>
                <div className="alert-message">{message}</div>
            </div>
        </>
    ) : null;
}

export default Alert;
