import { Backdrop, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import '../RentalSessionPopUps.css';
import './RentalSessionEndPopUp.css';
import PaymentMethodInputs from '../../../shared/PaymentMethodInputs/PaymentMethodInputs';
import { useOrgOptions } from '../../../provider/OrganizationOptionsProvider';

// TODO Too many re-renders on Mimdinare case
function RentalSessionEndPopUp({
    setEndPopUp,
    session,
    totalPrice,
    endSession,
    prepaidBalance,
    updateExpiredSessions,
    timestamp,
}) {
    const [payments, setPayments] = useState({
        cash: 0,
        credit: 0,
    });
    const [curTotalPrice, setCurTotalPrice] = useState(parseFloat(totalPrice));
    const [isLoading, setIsLoading] = useState(false);
    const { currency } = useOrgOptions();

    useEffect(() => {
        setCurTotalPrice(parseFloat(totalPrice) - parseFloat(prepaidBalance.totalPrepaid));
    }, [totalPrice, prepaidBalance]);

    const handlePaymentsInput = useCallback(
        (paymentInputs) => {
            setPayments({ ...paymentInputs });
        },
        [setPayments]
    );

    const handleEnd = useCallback(async () => {
        setIsLoading(true);
        if (
            await endSession({
                sessionId: session._id,
                endTimestamp: timestamp,
                paymentCash: payments.cash,
                paymentCredit: payments.credit,
                totalPrice,
            })
        ) {
            setEndPopUp(false);
            updateExpiredSessions(session, 'REMOVE');
        }
        setIsLoading(false);
    }, [
        session,
        endSession,
        payments.cash,
        payments.credit,
        totalPrice,
        setEndPopUp,
        updateExpiredSessions,
        timestamp,
        setIsLoading,
    ]);

    const handleCancel = useCallback(() => {
        if (!isLoading) {
            setEndPopUp(false);
        }
    }, [isLoading, setEndPopUp]);

    return (
        <>
            <div className="popUp-wrapper rentalCard-sessionEnd">
                <div className="totalPriceSection">
                    <span>Total Price : </span>
                    <span className="endPopUp-priceIndicators">
                        {totalPrice}
                        {currency.sign}
                    </span>
                </div>
                <div className="totalPriceSection">
                    <span>Price to Pay : </span>
                    <span className="endPopUp-priceIndicators">
                        {parseFloat(curTotalPrice).toFixed(2)}
                        {currency.sign}
                    </span>
                </div>
                <PaymentMethodInputs
                    maxTotalValue={curTotalPrice}
                    onInput={handlePaymentsInput}
                    strictMode={true}
                />
                <div className="sessionPopUp-actions">
                    <button
                        className="popUp-button cancel"
                        disabled={isLoading}
                        onClick={handleCancel}>
                        Cancel
                    </button>
                    <button
                        className="popUp-button start"
                        onClick={handleEnd}
                        disabled={
                            !(
                                !isLoading &&
                                parseFloat(payments.cash) + parseFloat(payments.credit) ===
                                    parseFloat(curTotalPrice)
                            )
                        }>
                        Done {isLoading ? <CircularProgress className="circularProgress" /> : null}
                    </button>
                </div>
            </div>

            <Backdrop className="alert-backdrop" open={true} onClick={handleCancel} />
        </>
    );
}

export default RentalSessionEndPopUp;
