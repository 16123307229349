import { useCallback, useEffect, useMemo, useState } from 'react';
import './ExportDailyStatisticsExcel.css';
import { CircularProgress, MenuItem, Popover, TextField } from '@mui/material';
import { useHttpClient } from '../../provider/HttpClientProvider';
import { useMutation, useQuery } from '@tanstack/react-query';
import ExcelJS from 'exceljs';
import { END_DAY_EVENT_TYPE } from '../utils/constants';
import { headerFont } from './excelStyles';
import { ORGANIZATION_EVENT_END_DAY_QUERY_KEY, USER_QUERY_KEY } from '../utils/queryConstants';

const emptyArray = [];

function ExportDailyStatisticsExcel({ onError }) {
    const { mutateAsync: getBalanceStats, isPending: isGettingBalanceStats } =
        useGetBalanceStats(onError);
    const [anchorEl, setAnchorEl] = useState(null);
    const { data: endDays = emptyArray } = useGetEndDayEvents();
    const { data: users = emptyArray } = useGetUsers();
    const [timestamps, setTimestamps] = useState({
        start: '',
        end: '',
    });

    const handleOpen = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const handleChange = useCallback(
        (event) => {
            const changeField = event.target.name;
            const newValue = event.target.value;

            setTimestamps((curState) => {
                return { ...curState, [changeField]: newValue };
            });
        },
        [setTimestamps]
    );

    useEffect(() => {
        if (endDays.length > 0) {
            setTimestamps((curState) => {
                return { ...curState, start: endDays[0].timestamp };
            });
        }
    }, [endDays]);

    const open = useMemo(() => {
        return Boolean(anchorEl);
    }, [anchorEl]);

    const id = useMemo(() => {
        return open ? 'simple-popover' : undefined;
    }, [open]);

    const generateSessionSheet = useCallback((sessionSheet, usernames, data) => {
        sessionSheet.columns = [
            {
                header: 'Start Date',
                key: 'startDate',
            },
            {
                header: 'Expected End Date',
                key: 'endDate',
            },
            {
                header: 'Actual End Date',
                key: 'actualEndDate',
            },
            {
                header: 'Category',
                key: 'rentalCategoryName',
            },
            {
                header: 'Rental',
                key: 'rentalName',
            },
            {
                header: 'rentalTypes',
                key: 'rentalTypes',
            },

            {
                header: 'Products',
                key: 'usedProducts',
            },
            {
                header: 'Cash Payment',
                key: 'paymentCash',
            },
            {
                header: 'Credit Payment',
                key: 'paymentCredit',
            },
            {
                header: 'Total Price',
                key: 'totalPrice',
            },
            {
                header: 'Session Events',
                key: 'sessionEvents',
            },
        ];

        data?.sessionStats?.forEach((session) => {
            sessionSheet.addRow({
                startDate: timestampToDate(session.startTimestamp),
                endDate: session.endTimestamp ? timestampToDate(session.endTimestamp) : '',
                actualEndDate: session.actualEndTimestamp
                    ? timestampToDate(session.actualEndTimestamp)
                    : '',
                rentalName: session.rentalName,
                rentalTypes: session.rentalTypes,
                rentalCategoryName: session.rentalCategoryName,
                usedProducts: session.usedProducts,
                paymentCash: session.paymentCash.$numberDecimal,
                paymentCredit: session.paymentCredit.$numberDecimal,
                totalPrice: session.totalPrice.$numberDecimal,
                sessionEvents: session.events,
            });
        });

        sessionSheet.getRow(1).font = headerFont;

        sessionSheet.getColumn('usedProducts').eachCell((cell, rowNumber) => {
            if (rowNumber === 1) {
                return;
            }

            if (cell.value.length) {
                cell.value = cell.value
                    .map((product) => {
                        return product.name + ' ' + product.quantity;
                    })
                    .toString();
            } else {
                cell.value = '';
            }
        });

        sessionSheet.getColumn('rentalTypes').eachCell((cell, rowNumber) => {
            if (rowNumber === 1) {
                return;
            }

            if (cell.value.length) {
                cell.value = cell.value
                    .map((type) => {
                        return type.name;
                    })
                    .toString();
            } else {
                cell.value = '';
            }
        });

        const eventToString = (event) => {
            return (
                '"' +
                [
                    event.type,
                    timestampToDate(event.timestamp),
                    usernames[event.userId] ?? event.userId,
                    ...(event.message ? [event.message] : []),
                ].join(' - ') +
                ',"'
            );
        };
        sessionSheet.getColumn('sessionEvents').eachCell((cell, rowNumber) => {
            if (rowNumber === 1) {
                return;
            }

            if (cell.value.length) {
                cell.value = {
                    formula: cell.value.map((event) => eventToString(event)).join('&CHAR(10)&'),
                };
            } else {
                cell.value = '';
            }
        });
    }, []);

    const generateProductsSheet = useCallback((productsSheet, usernames, data) => {
        productsSheet.columns = [
            {
                header: 'Sale Date',
                key: 'date',
            },
            {
                header: 'Products',
                key: 'products',
            },
            {
                header: 'Cash Payment',
                key: 'paymentCash',
            },
            {
                header: 'Credit Payment',
                key: 'paymentCredit',
            },
            {
                header: 'Total Price',
                key: 'totalPrice',
            },
            {
                header: 'User',
                key: 'user',
            },
        ];

        data?.productSaleStats?.forEach((productSale) => {
            productsSheet.addRow({
                date: timestampToDate(productSale.timestamp),
                products: productSale.products,
                paymentCash: productSale.paymentCash.$numberDecimal,
                paymentCredit: productSale.paymentCredit.$numberDecimal,
                totalPrice: productSale.totalPrice.$numberDecimal,
                user: usernames[productSale.userId] ?? productSale.userId,
            });
        });

        productsSheet.getRow(1).font = headerFont;

        productsSheet.getColumn('products').eachCell((cell, rowNumber) => {
            if (rowNumber !== 1) {
                if (cell.value.length) {
                    cell.value = cell.value
                        .map((product) => {
                            return product.name + ' ' + product.quantity;
                        })
                        .toString();
                } else cell.value = '';
            }
        });
    }, []);

    const generateCashRegistrySheet = useCallback((cashRegistrySheet, usernames, data) => {
        cashRegistrySheet.columns = [
            {
                header: 'Event Date',
                key: 'date',
            },
            {
                header: 'Category',
                key: 'category',
            },
            {
                header: 'Type',
                key: 'type',
            },
            {
                header: 'Old Balance',
                key: 'currentBalance',
            },
            {
                header: 'Withdrew Amount',
                key: 'withdrewAmount',
            },
            {
                header: 'New Balance',
                key: 'updatedBalance',
            },
            {
                header: 'User',
                key: 'user',
            },
            {
                header: 'Note',
                key: 'message',
            },
        ];

        data?.eventsStats?.forEach((event) => {
            cashRegistrySheet.addRow({
                date: timestampToDate(event.timestamp),
                category: event.category,
                type: event.type,
                currentBalance: event.value.currentBalance,
                withdrewAmount:
                    event.type === END_DAY_EVENT_TYPE
                        ? event.value.withdrewAmount * -1
                        : event.value.value,
                updatedBalance:
                    event.type === END_DAY_EVENT_TYPE
                        ? event.value.value
                        : event.value.updatedBalance,
                user: usernames[event.userId] ?? event.userId,
                message: event.message,
            });
        });

        cashRegistrySheet.getRow(1).font = headerFont;
    }, []);

    const downlodGeneratedExcel = useCallback((workbook, startTimestamp, endTimestamp) => {
        workbook.xlsx.writeBuffer().then((data) => {
            const startDate = timestampToDate(startTimestamp);
            const endDate = timestampToDate(endTimestamp ? endTimestamp : Date.now());

            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');

            anchor.href = url;
            anchor.download = `${startDate}-${endDate}.xlsx`;
            anchor.click();

            window.URL.revokeObjectURL(url);
        });
    }, []);

    const exportData = useCallback(
        (data) => {
            const workbook = new ExcelJS.Workbook();
            const usernames = users.reduce((curMap, user) => {
                return { ...curMap, [user._id]: user.username };
            }, {});

            const sessionSheet = workbook.addWorksheet('Sessions');
            generateSessionSheet(sessionSheet, usernames, data);

            const productsSheet = workbook.addWorksheet('Products');
            generateProductsSheet(productsSheet, usernames, data);

            const cashRegistrySheet = workbook.addWorksheet('Cash Registry');
            generateCashRegistrySheet(cashRegistrySheet, usernames, data);

            downlodGeneratedExcel(workbook, timestamps.start, timestamps.end);
        },
        [
            generateSessionSheet,
            generateProductsSheet,
            generateCashRegistrySheet,
            downlodGeneratedExcel,
            timestamps,
            users,
        ]
    );

    const generateAndExportExcel = useCallback(async () => {
        const balanceStats = await getBalanceStats(timestamps.start, timestamps.end);

        if (balanceStats) {
            exportData(balanceStats);
        }
    }, [exportData, getBalanceStats, timestamps.start, timestamps.end]);

    return (
        <div className="excelDownload-wrapper">
            <button
                aria-describedby={id}
                className="export-popover-button"
                variant="contained"
                onClick={handleOpen}>
                <img src="\excelLogo.png" className="excel-download-png" alt="" />
            </button>
            <Popover
                id={id}
                className="dailyExport-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <div className="dailyExport-dataPicker-item">
                    <TextField
                        select
                        fullWidth
                        label="Start"
                        id="start"
                        value={timestamps.start}
                        size="small"
                        name="start"
                        className="dailyExport-DatePicker"
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: {
                                        maxHeight: 250,
                                    },
                                },
                            },
                        }}
                        onChange={handleChange}>
                        {endDays.map((event) => (
                            <MenuItem
                                className="dailyExport-menuItem"
                                key={event._id}
                                value={event.timestamp}>
                                {timestampToDate(event.timestamp)}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="dailyExport-dataPicker-item">
                    <TextField
                        select
                        label="End"
                        id="end"
                        value={timestamps.end}
                        size="small"
                        name="end"
                        className="dailyExport-DatePicker"
                        placeholder="Select last Date"
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: {
                                        maxHeight: 250,
                                    },
                                },
                            },
                        }}
                        onChange={handleChange}>
                        <MenuItem className="dailyExport-menuItem" value="">
                            <em>select Last Date</em>
                        </MenuItem>
                        {endDays
                            .filter((event) => event.timestamp > timestamps.start)
                            .map((event) => (
                                <MenuItem
                                    className="dailyExport-menuItem"
                                    key={event._id}
                                    value={event.timestamp}>
                                    {timestampToDate(event.timestamp)}
                                </MenuItem>
                            ))}
                    </TextField>
                </div>
                <button
                    disabled={isGettingBalanceStats}
                    className="popUp-button start daily-export-button"
                    onClick={generateAndExportExcel}>
                    Export
                    {isGettingBalanceStats ? (
                        <CircularProgress size={'1rem'} color="inherit" />
                    ) : null}
                </button>
            </Popover>
        </div>
    );
}

export default ExportDailyStatisticsExcel;

function useGetEndDayEvents() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [ORGANIZATION_EVENT_END_DAY_QUERY_KEY, 30],
        queryFn: async () => {
            return await httpClient
                .get(`/organizationEvent?category=CASH_REGISTRY&type=END_DAY&limit=${30}`)
                .then((result) => {
                    if (result.status === 200) {
                        return result.data;
                    }
                });
        },
        refetchInterval: 5 * 60 * 1000,
    });
}

function useGetUsers() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [USER_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get(`/user`).then((result) => {
                if (result.status === 200) {
                    return result.data;
                }
            });
        },
        refetchInterval: 5 * 60 * 1000,
    });
}

function useGetBalanceStats(setError) {
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (start, end) => {
            return await httpClient
                .get(`/stats/balance?startTimestamp=${start}&endTimestamp=${end}`)
                .then((result) => {
                    if (result.status === 200) {
                        return result.data;
                    }
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                });
        },
    });
}

const timestampToDate = (timestamp) => {
    return new Date(parseInt(timestamp)).toLocaleString();
};
