import { Backdrop, CircularProgress, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import '../RentalSessionPopUps.css';
import './RentalSessionAdminEditPopUp.css';
import PaymentMethodInputs from '../../../shared/PaymentMethodInputs/PaymentMethodInputs';
import { useOrgOptions } from '../../../provider/OrganizationOptionsProvider';

function RentalSessionAdminEditPopup({ session, onClose, onEdit }) {
    const [isFormEdited, setIsFormEdited] = useState(false);
    const [editForm, setEditForm] = useState({
        cash: parseFloat(session.paymentCash.$numberDecimal),
        credit: parseFloat(session.paymentCredit.$numberDecimal),
        message: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const { currency } = useOrgOptions();

    useEffect(() => {
        if (
            editForm.cash !== parseFloat(session.paymentCash.$numberDecimal) ||
            editForm.credit !== parseFloat(session.paymentCredit.$numberDecimal)
        ) {
            setIsFormEdited(true);
        } else {
            setIsFormEdited(false);
        }
    }, [editForm, session]);

    const handleNoteChange = useCallback(
        (event) => {
            setEditForm((curState) => {
                return { ...curState, [event.target.name]: event.target.value };
            });
        },
        [setEditForm]
    );

    const handlePaymentInputChange = useCallback((values) => {
        setEditForm((curState) => {
            return { ...curState, ...values };
        });
    }, []);

    const handleSave = useCallback(async () => {
        setIsLoading(true);
        if (
            await onEdit({
                sessionId: session._id,
                paymentCash: editForm.cash,
                paymentCredit: editForm.credit,
                message: editForm.message,
            })
        ) {
            onClose();
        }
        setIsLoading(false);
    }, [onEdit, onClose, editForm, session._id]);

    return (
        <>
            <div className="popUp-wrapper">
                <h3 className="sessionEdit-header">Edit Session</h3>
                <div className="sessionEdit-body">
                    <div className="totalPriceSection">
                        <span>Total Price : </span>
                        <span className="endPopUp-priceIndicators">
                            {(editForm.cash + editForm.credit).toFixed(2)}
                            {currency.sign}
                        </span>
                    </div>
                    <PaymentMethodInputs
                        value={{
                            cash: session.paymentCash.$numberDecimal,
                            credit: session.paymentCredit.$numberDecimal,
                        }}
                        onInput={handlePaymentInputChange}
                        showChangeCalculator={false}
                    />
                    <div className="sessionEdit-item sessionEdit-note">
                        <TextField
                            id="message"
                            label="Add Note"
                            variant="standard"
                            name="message"
                            value={editForm.message}
                            onChange={handleNoteChange}
                            InputLabelProps={{ shrink: true }}
                            className="sessionEdit-noteIput"
                        />
                    </div>
                </div>
                <div className="sessionPopUp-actions">
                    <button className="popUp-button cancel" onClick={onClose} disabled={isLoading}>
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        disabled={!isFormEdited || isLoading}
                        className="popUp-button start">
                        {isLoading ? <CircularProgress className="circularProgress" /> : null}
                        Save
                    </button>
                </div>
            </div>
            <Backdrop className="alert-backdrop" open={true} onClick={onClose} />
        </>
    );
}

export default RentalSessionAdminEditPopup;
