import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useHttpClient } from '../../provider/HttpClientProvider';
import {
    DASHBOARD_PIE_CHART_QUERY_KEY,
    ORGANIZATION_EVENT_END_DAY_QUERY_KEY,
} from '../../shared/utils/queryConstants';
import { useQuery } from '@tanstack/react-query';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PieChart } from '@mui/x-charts';
import { convertDateRangeToTimestamp } from '../../shared/utils/timestampUtils';
import { CHART_COLORS } from '../dashboardConstans';
import '../Dashboard.css';
import './PieChartWidget.css';

const emptyResult = [];
const LOADING_PIE = [{ id: 1, value: 100, label: 'LOADING', color: 'var(--primary-grey)' }];
const NO_DATA_PIE = [
    { id: 1, value: 100, label: 'No Stats Available', color: 'var(--primary-red)' },
];

function PieChartWidget() {
    const [timestamp, setTimestamp] = useState({
        start: null,
        end: null,
    });

    const { data: endDays = emptyResult } = useGetEndDayEvents();

    const { data: incomes = emptyResult, isFetching: isIncomesFetching } = useGetIncomes(
        timestamp.start,
        timestamp.end
    );

    useEffect(() => {
        if (endDays.length > 0) {
            setTimestamp((curState) => {
                return { ...curState, start: dayjs(parseInt(endDays[0].timestamp)) };
            });
        }
    }, [endDays]);

    const chartSeries = useMemo(() => {
        if (incomes.length) {
            return incomes
                .map((income) => {
                    return {
                        id: income._id,
                        value: parseFloat(income.sum.$numberDecimal),
                        label: income.name,
                    };
                })
                .sort((a, b) => {
                    return b.value - a.value;
                });
        }
        return isIncomesFetching ? LOADING_PIE : NO_DATA_PIE;
    }, [incomes, isIncomesFetching]);

    const handleDatePick = useCallback(
        (value, targetedDate) => {
            setTimestamp((curDateRange) => ({ ...curDateRange, [targetedDate]: value }));
        },
        [setTimestamp]
    );

    return (
        <div className="dashboard-pieChart-wrapper">
            <h2 className="dashboard-chart-title">Income Pie Chart</h2>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                    <div className="dashboard-dataTimePickers-wrapper">
                        <DateTimePicker
                            name="startDate"
                            ampm={false}
                            label="Start Date"
                            value={timestamp.start}
                            onChange={(newValue) => handleDatePick(newValue, 'start')}
                            className="dashboard-dataTimePickers-item"
                            disableFuture={true}
                        />

                        <DateTimePicker
                            name="startDate"
                            ampm={false}
                            label="End Date"
                            value={timestamp.end}
                            onChange={(newValue) => handleDatePick(newValue, 'end')}
                            className="dashboard-dataTimePickers-item"
                            disableFuture={true}
                        />
                    </div>
                </DemoContainer>
            </LocalizationProvider>

            <PieChart
                series={[
                    {
                        data: chartSeries,
                        innerRadius: 20,
                        outerRadius: 150,
                        paddingAngle: 1,
                        cornerRadius: 5,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30 },
                        arcLabelMinAngle: 45,
                    },
                ]}
                margin={{ top: 100, bottom: 100, left: -70, right: 100 }}
                slotProps={{
                    legend: {
                        labelStyle: {
                            fontSize: '1.2rem',
                            fill: 'white',
                        },
                        direction: 'column',
                        position: { vertical: 'middle', horizontal: 'right' },
                    },
                }}
                colors={CHART_COLORS}
            />
        </div>
    );
}

export default PieChartWidget;

function useGetIncomes(start, end) {
    const httpClient = useHttpClient();

    start = convertDateRangeToTimestamp(start?.$d);
    end = convertDateRangeToTimestamp(end?.$d);

    return useQuery({
        queryKey: [DASHBOARD_PIE_CHART_QUERY_KEY, start, end],
        queryFn: async () => {
            if (!start) {
                return;
            }

            return await httpClient
                .get(`/stats/income?startTimestamp=${start}&endTimestamp=${end}`)
                .then((result) => {
                    if (result.status === 200) {
                        return result.data;
                    }
                });
        },
        refetchInterval: 5 * 60 * 1000,
    });
}

function useGetEndDayEvents() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [ORGANIZATION_EVENT_END_DAY_QUERY_KEY, 1],
        queryFn: async () => {
            return await httpClient
                .get(`/organizationEvent?category=CASH_REGISTRY&type=END_DAY&limit=${1}`)
                .then((result) => {
                    if (result.status === 200) {
                        return result.data;
                    }
                });
        },
        refetchInterval: 5 * 60 * 1000,
    });
}
