import { useState } from 'react';

import { useAuth } from '../provider/AuthProvider';
import { useHttpClient } from '../provider/HttpClientProvider';

import './Auth.css';

function Auth() {
    const { setAuthToken } = useAuth();
    const httpClient = useHttpClient();

    const [credentials, setCredentials] = useState({ username: '', password: '' });

    const handleChange = (e) => {
        setCredentials((curState) => {
            return { ...curState, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // TODO handle error using .catch()
        httpClient.post('/user/login', credentials).then((result) => {
            if (result.status === 200) {
                setAuthToken(result.data.accessToken);
            }
        });
    };
    return (
        <div className="login-container">
            <form onSubmit={handleSubmit}>
                <span className="login-title">Mimdinare</span>

                <label htmlFor="username">Username</label>
                <input
                    type="text"
                    className="username"
                    id="username"
                    name="username"
                    value={credentials.username}
                    onChange={handleChange}
                    required
                />
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    className="password"
                    id="password"
                    name="password"
                    value={credentials.password}
                    onChange={handleChange}
                    required
                />
                <button type="submit">Log in</button>
            </form>
        </div>
    );
}

export default Auth;
