import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo } from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';

function ProductSelectionTable({ columns, products, enableToolBar, enableHeader, height }) {
    const table = useMaterialReactTable({
        columns,

        data: products,

        enableColumnOrdering: false,

        enableDensityToggle: false,

        enablePagination: false,

        enableEditing: false,

        enableRowActions: false,

        enableColumnFilters: false,

        enableSorting: false,

        enableTopToolbar: enableToolBar,

        enableColumnFilterModes: false,

        enableHiding: false,

        searchOpen: true,

        enableFullScreenToggle: false,

        enableColumnActions: false,

        enableBottomToolbar: false,

        enableTableHead: enableHeader,

        layoutMode: 'grid-grow',

        getRowId: (row) => row._id,

        muiTablePaperProps: {
            sx: {
                '&  > .MuiTableContainer-root': {
                    display: 'flex',
                    justifyContent: 'center',
                },
            },
        },

        muiTableProps: {
            sx: {
                display: 'flex',
                flexDirection: 'column',
            },
        },

        muiTopToolbarProps: {
            sx: {
                margin: ' 0 auto 0 ',
                width: '15rem',
                height: '3rem',

                '& .MuiBox-root > .MuiIconButton-root': {
                    display: 'none',
                },
                '& .MuiBox-root *': {
                    fontSize: '0.8rem',
                },
            },
        },

        muiTableHeadCellProps: {
            sx: {
                '& *': {
                    justifyContent: 'center',
                },
            },
        },

        muiTableHeadRowProps: {
            sx: {
                '& > *': {
                    flex: '1',
                },
                '& > th:first-of-type > div ': {
                    justifyContent: 'left',
                },
                '& > th': { fontSize: '0.7rem', letterSpacing: '0.05rem' },
            },
        },

        muiTableBodyCellProps: {
            sx: {
                padding: 0,
                fontSize: '0.7rem',
            },
        },

        muiTableBodyRowProps: {
            sx: {
                '& > td:first-of-type': { paddingLeft: '10px' },
                width: '100%',
                '& > *': {
                    flex: '1',
                },
            },
        },

        muiTableContainerProps: {
            sx: {
                overflowY: 'scroll',
                height: height,
            },
        },

        muiSearchTextFieldProps: {
            sx: {
                '& * div span *': {
                    color: 'white',
                },
            },
        },

        defaultColumn: {
            size: 100,
        },

        state: { showGlobalFilter: true },
    });

    const globalTheme = useTheme();

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    text: {
                        primary: '#fff',
                        secondary: '#fff',
                    },
                    mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                    primary: {
                        main: '#24305e',
                    }, //swap in the secondary color as the primary for the table
                    info: {
                        main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default: '#24305e',
                    },
                },
                typography: {
                    body1: {
                        color: 'white',
                    },
                    body2: {
                        color: 'white',
                    },
                    button: {
                        textTransform: 'none', //customize typography styles for all buttons in table by default
                        fontSize: '1.2rem',
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: '1.1rem', //override to make tooltip font size larger
                            },
                        },
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            thumb: {
                                color: 'pink', //change the color of the switch thumb in the columns show/hide menu to pink
                            },
                        },
                    },
                },
            }),
        [globalTheme]
    );

    return (
        <ThemeProvider theme={tableTheme}>
            <MaterialReactTable table={table} />
        </ThemeProvider>
    );
}

export default ProductSelectionTable;
