import { useCallback, useMemo, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import './ChangeCalculator.css';
import { useOrgOptions } from '../../provider/OrganizationOptionsProvider';

export default function ChangeCalculator({ priceToPay }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [receivedMoney, setReceivedMoney] = useState('');
    const receivedMoneyInput = useRef(null);
    const { currency } = useOrgOptions();

    const open = useMemo(() => {
        return Boolean(anchorEl);
    }, [anchorEl]);
    const id = useMemo(() => {
        return open ? 'simple-popover' : undefined;
    }, [open]);

    const handleModifyAmountChange = useCallback(
        (e) => {
            setReceivedMoney(e.target.value?.replace(/^0+/, ''));
        },
        [setReceivedMoney]
    );

    const handleModifyAmountKeyDown = useCallback((e) => {
        if (e.key === '-' || e.key === 'e') {
            e.preventDefault();
        }
    }, []);

    const handleOpen = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget);
            setTimeout(() => receivedMoneyInput.current.focus(), 10);
        },
        [setAnchorEl, receivedMoneyInput]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    return (
        <div className="calculatorWrapper">
            <IconButton
                aria-describedby={id}
                variant="contained"
                onClick={handleOpen}
                className="changeCalculatorButton">
                <CalculateOutlinedIcon className="calculatorIcon" />
            </IconButton>
            <Popover
                id={id}
                sx={{
                    zIndex: '10000',
                }}
                className="calculatorPopover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <div className="calculatorPopup">
                    <div className="closeIconRow">
                        <CloseIcon onClick={handleClose} className="closeIcon" />
                    </div>
                    <div className="calculatorItem">
                        <label htmlFor="amountReceived">Amount Received:</label>
                        <label
                            htmlFor="amountReceived"
                            className="amountReceivedLabel"
                            currency-sign={currency.sign}>
                            <input
                                ref={receivedMoneyInput}
                                className="amountReceivedInput"
                                type="number"
                                id="amountReceived"
                                value={receivedMoney}
                                placeholder="0"
                                onInput={handleModifyAmountChange}
                                onKeyDown={handleModifyAmountKeyDown}
                                autoFocus
                            />
                        </label>
                    </div>
                    <div className="calculatorItem priceToPayRow">
                        <label htmlFor="priceToPay">Price to Pay:</label>
                        <div id="priceToPay" className="priceToPayNumber">
                            {priceToPay}
                            {currency.sign}
                        </div>
                    </div>
                    <div className="calculatorItem changeRow">
                        <label htmlFor="change">Change:</label>
                        <div id="change" className="changeNumber">
                            {(receivedMoney - priceToPay).toFixed(2)}
                            {currency.sign}
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    );
}
