import { Backdrop, CircularProgress, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TimeInput from '../../../shared/TimeInput/TimeInput';
import '../RentalSessionPopUps.css';
import './RentalSessionSetupPopUp.css';
import Select from 'react-select';
import {
    calculateCurrentSessionPrice,
    calculateHourlyRate,
    calculateMinuteDurationForPrice,
    isFixedType,
} from '../../rentalSessionUtils';
import PaymentMethodInputs from '../../../shared/PaymentMethodInputs/PaymentMethodInputs';
import { useOrgOptions } from '../../../provider/OrganizationOptionsProvider';
import { useStartRentalSession } from '../../../provider/RentalSessionsWrapperProvider';

function RentalSessionSetupPupUp({
    rentalTypes = [],
    hourlyRate,
    setSessionSetup,
    rentalId,
    setError,
    customRates,
}) {
    const [sessionType, setSessionType] = useState('fixed');
    const [rentalType, setRentalType] = useState([]);
    const [duration, setDuration] = useState(0);
    const [price, setPrice] = useState(0);
    const [payments, setPayments] = useState({
        cash: 0,
        credit: 0,
    });
    const [isLoading, setIsLoading] = useState(false);
    const { currency } = useOrgOptions();

    const { mutateAsync: startSession } = useStartRentalSession(setError);

    useEffect(() => {
        const selectedTypesWithPrices = [];
        if (rentalType) {
            for (let type of rentalTypes) {
                if (rentalType.includes(type._id.toString())) {
                    selectedTypesWithPrices.push({
                        hourlyRateIncrease: { ...type.hourlyRateIncrease },
                    });
                }
            }
        }

        setPrice(
            isFixedType(sessionType)
                ? calculateCurrentSessionPrice(
                      duration,
                      hourlyRate,
                      customRates,
                      selectedTypesWithPrices
                  )
                : calculateHourlyRate(hourlyRate, selectedTypesWithPrices)
        );
    }, [sessionType, duration, rentalType, hourlyRate, rentalTypes, customRates]);

    const handleMoneyInputOptionChange = useCallback(
        (val) => {
            const selectedTypesWithPrices = [];
            if (rentalType) {
                for (let type of rentalTypes) {
                    if (rentalType.includes(type._id.toString())) {
                        selectedTypesWithPrices.push({
                            hourlyRateIncrease: { ...type.hourlyRateIncrease },
                        });
                    }
                }
            }

            return calculateMinuteDurationForPrice(
                val,
                hourlyRate,
                customRates,
                selectedTypesWithPrices
            );
        },
        [customRates, hourlyRate, rentalType, rentalTypes]
    );

    const calculatePrePaidValue = useCallback(
        (paymentType) => {
            return payments.cash || payments.credit ? [paymentType] : [];
        },
        [payments]
    );

    const handlePaymentsInput = useCallback(
        (paymentInputs) => {
            setPayments({ ...paymentInputs });
        },
        [setPayments]
    );

    const handleStart = useCallback(async () => {
        setIsLoading(true);
        const timestamp = Date.now();
        const postSessionStart = {
            rentalId,
            startTimestamp: timestamp,
            priceType: sessionType.toUpperCase(),
            endTimestamp: isFixedType(sessionType) ? timestamp + duration * 60 * 1000 : null,
            rentalTypeIds: rentalType,
            prePaidCash: calculatePrePaidValue(payments.cash),
            prePaidCredit: calculatePrePaidValue(payments.credit),
        };

        if (await startSession(postSessionStart)) {
            setSessionSetup(false);
        }
        setIsLoading(false);
    }, [
        rentalId,
        sessionType,
        duration,
        rentalType,
        setSessionSetup,
        startSession,
        payments.cash,
        payments.credit,
        calculatePrePaidValue,
        setIsLoading,
    ]);

    const handleCancel = useCallback(() => {
        if (!isLoading) {
            setSessionSetup(false);
        }
    }, [isLoading, setSessionSetup]);

    const customStyles = useMemo(
        () => ({
            option: (base, { isFocused }) => {
                return {
                    ...base,
                    backgroundColor: isFocused ? 'var(--primary-color)' : 'var(--secondary-blue)',
                };
            },
            menu: (base) => {
                return {
                    ...base,
                    backgroundColor: 'var(--secondary-blue)',
                };
            },
            control: (base) => {
                return {
                    ...base,
                    backgroundColor: 'transparent',
                    width: '100%',
                    border: 'none',
                    boxShadow: 'none',
                };
            },
            container: (base) => {
                return {
                    ...base,
                    borderColor: 'transperent',
                    borderBottom: '1px solid rgb(160, 149, 149)',
                };
            },
        }),
        []
    );

    const handleRentalTypeChange = useCallback(
        (event) => {
            setRentalType([event?.value]);
        },
        [setRentalType]
    );

    const getRentalOptions = useCallback(() => {
        return rentalTypes.map((type) => {
            return { value: type._id, label: type.name };
        });
    }, [rentalTypes]);

    return (
        <>
            <div className="popUp-wrapper rentalCard-sessionSetup">
                <div className="sessionSetup-header">
                    <RadioGroup
                        onChange={(e) => setSessionType(e.target.value)}
                        value={sessionType}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group">
                        <FormControlLabel
                            value={'fixed'}
                            control={
                                <Radio
                                    sx={{
                                        color: blue[50],
                                        '&.Mui-checked': {
                                            color: blue[50],
                                        },
                                    }}
                                />
                            }
                            label="Fixed"
                        />
                        <FormControlLabel
                            value={'CONTINUES'}
                            control={
                                <Radio
                                    sx={{
                                        color: blue[50],
                                        '&.Mui-checked': {
                                            color: blue[50],
                                        },
                                    }}
                                />
                            }
                            label="Mimdinare"
                        />
                    </RadioGroup>
                </div>
                <div className="sessionSetup-body">
                    {rentalTypes && rentalTypes.length ? (
                        <div className="sessionSetup-rentalType sessionSetup-item">
                            <div>
                                <label htmlFor="rentalType">Rental Type:</label>
                            </div>
                            <div className="sessionSetup-inputClass">
                                <Select
                                    options={getRentalOptions()}
                                    isClearable
                                    placeholder="Select type..."
                                    name="rentalType"
                                    id="rentalType"
                                    onChange={handleRentalTypeChange}
                                    classNamePrefix="react-select"
                                    className="inputWindows"
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="sessionSetup-timeDuration sessionSetup-item">
                        <label htmlFor="timeInput">Time Duration:</label>
                        <div className="sessionSetup-inputClass">
                            <TimeInput
                                disabled={!isFixedType(sessionType)}
                                onChange={setDuration}
                                moneyInputOptionConfig={
                                    isFixedType(sessionType)
                                        ? {
                                              active: true,
                                              onChange: handleMoneyInputOptionChange,
                                              totalPrice: price,
                                          }
                                        : null
                                }
                            />
                        </div>
                    </div>

                    <div className="sessionSetup-price sessionSetup-item">
                        <label htmlFor="price">
                            {isFixedType(sessionType) ? 'Total Price:' : 'Hourly Price:'}
                        </label>
                        <div className="sessionSetup-inputClass">
                            <input
                                type="text"
                                id="price"
                                disabled
                                value={price + currency.sign}
                                className="priceInputs sessionSetup-priceInput"
                            />
                        </div>
                    </div>
                </div>

                <div className="sessionSetup-footer">
                    <div className="sessionSetup-item">
                        {isFixedType(sessionType) ? (
                            <PaymentMethodInputs
                                maxTotalValue={price}
                                onInput={handlePaymentsInput}
                                strictMode={false}
                            />
                        ) : null}
                    </div>
                    <div className="sessionPopUp-actions">
                        <button
                            className="popUp-button cancel"
                            disabled={isLoading}
                            onClick={handleCancel}>
                            Cancel
                        </button>
                        <button
                            className="popUp-button start"
                            disabled={isLoading}
                            onClick={handleStart}>
                            Start
                            {isLoading ? <CircularProgress className="circularProgress" /> : null}
                        </button>
                    </div>
                </div>
            </div>
            <Backdrop className="alert-backdrop" open={true} onClick={handleCancel} />
        </>
    );
}

export default RentalSessionSetupPupUp;
