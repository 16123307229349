import { Box, IconButton, Tooltip } from '@mui/material';
import { MaterialReactTable, createRow, useMaterialReactTable } from 'material-react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

const emptyObject = { timeUnit: 0, rate: { $numberDecimal: 0 } };

function CutomRatesInput({ customRates = [], onChange }) {
    const [validationErrors, setValidationErrors] = useState({});
    const [curCustomRates, setCurCustomRates] = useState(customRates);

    useEffect(() => {
        onChange(curCustomRates);
    }, [curCustomRates, onChange]);

    const columns = useMemo(
        () => [
            {
                header: 'Time Unit',
                accessorKey: 'timeUnit',
                size: 140,
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.timeUnit,
                    helperText: validationErrors?.timeUnit,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            timeUnit: undefined,
                        }),
                },
            },
            {
                header: 'Rate',
                id: 'rate',
                accessorFn: (dataRow) => parseFloat(dataRow?.rate?.$numberDecimal),
                size: 130,
                muiEditTextFieldProps: {
                    type: 'number',
                    required: true,
                    error: !!validationErrors?.rate,
                    helperText: validationErrors?.rate,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            rate: undefined,
                        }),
                },
            },
        ],
        [validationErrors]
    );

    //CREATE action
    const handleCreateCustomRate = useCallback(async ({ values, table }) => {
        const newValidationErrors = validateCustomRate(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }

        setValidationErrors({});
        setCurCustomRates((curRates) => [
            {
                ...values,
                rate: { $numberDecimal: values.rate },
                tempId: (Math.random() + 1).toString(36).substring(7),
            },
            ...curRates,
        ]);
        table.setCreatingRow(null);
    }, []);

    //UPDATE action
    const handleSaveCustomRate = useCallback(async ({ values, row, table }) => {
        const newValidationErrors = validateCustomRate(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }

        setValidationErrors({});
        setCurCustomRates((curRates) =>
            curRates.map((type) =>
                type._id === row.id || type.tempId === row.id
                    ? {
                          ...values,
                          _id: row.original._id,
                          rate: {
                              $numberDecimal: values.rate.toString(),
                          },
                      }
                    : { ...type }
            )
        );
        table.setEditingRow(null);
    }, []);

    //DELETE action
    const openDeleteConfirmModal = useCallback((row) => {
        if (window.confirm('Are you sure you want to delete this custom rate?')) {
            setCurCustomRates((curRates) => {
                return curRates.filter((type) => type._id !== row.id && type.tempId !== row.id);
            });
        }
    }, []);

    const table = useMaterialReactTable({
        columns,

        data: curCustomRates,

        enableColumnOrdering: false,

        enableDensityToggle: false,

        enablePagination: false,

        enableEditing: true,

        enableRowActions: true,

        enableColumnFilters: false,

        enableSorting: false,

        enableColumnFilterModes: false,

        enableHiding: false,

        enableFullScreenToggle: false,

        enableColumnActions: false,

        enableBottomToolbar: false,

        positionActionsColumn: 'last',

        createDisplayMode: 'row',

        editDisplayMode: 'row',

        layoutMode: 'grid-no-grow',

        getRowId: (row) => (row.tempId ? row.tempId : row._id),

        onCreatingRowCancel: () => setValidationErrors({}),

        onCreatingRowSave: handleCreateCustomRate,

        onEditingRowCancel: () => setValidationErrors({}),

        onEditingRowSave: handleSaveCustomRate,

        muiTableHeadCellProps: {},

        muiTableBodyRowProps: {
            hover: false,
            sx: {
                // height: '6vh',
            },
        },

        muiTableContainerProps: {
            sx: {
                overflowY: 'scroll',
                height: '30vh',
            },
        },

        muiSearchTextFieldProps: {
            sx: {
                width: '10rem',
            },
        },

        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),

        renderTopToolbarCustomActions: ({ table }) => (
            <IconButton
                variant="contained"
                onClick={() => {
                    table.setCreatingRow(createRow(table, emptyObject));
                }}>
                <AddIcon style={{ color: 'green' }} />
                <span style={{ marginLeft: '10px', fontSize: '0.9rem', color: 'black' }}>
                    Add Custom Rate
                </span>
            </IconButton>
        ),

        defaultColumn: {
            minSize: 105,
            maxSize: 150,
        },

        state: {},
    });

    return <MaterialReactTable table={table} />;
}

const validateDecimal = (value) => !!value && parseFloat(value) > 0;

function validateCustomRate(customRate) {
    return {
        timeUnit: !validateDecimal(customRate.timeUnit) ? 'Required > 0' : '',
        rate: !validateDecimal(customRate.rate) ? 'Required > 0' : '',
    };
}

export default CutomRatesInput;
