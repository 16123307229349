import { useMemo, useState } from 'react';
import { useHttpClient } from '../provider/HttpClientProvider';
import './ProductTable.css';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import TableWrapper from '../shared/TableWrapper/TableWrapper';
import { PRODUCT_QUERY_KEY } from '../shared/utils/queryConstants';

const emptyObject = { name: '', price: { $numberDecimal: 0 } };

// TODO Add error handling to every request
function ProductTable() {
    const [validationErrors, setValidationErrors] = useState({});

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.name,
                    helperText: validationErrors?.name,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            name: undefined,
                        }),
                },
            },
            {
                header: 'Price',
                id: 'price',
                accessorFn: (dataRow) => parseFloat(dataRow?.price?.$numberDecimal),
                muiEditTextFieldProps: {
                    type: 'price',
                    required: true,
                    error: !!validationErrors?.price,
                    helperText: validationErrors?.price,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            price: undefined,
                        }),
                },
            },
        ],
        [validationErrors]
    );

    return (
        <TableWrapper
            columns={columns}
            useCreate={useCreateProduct}
            useUpdate={useUpdateProduct}
            useGet={useGetProducts}
            useDelete={useDeleteProduct}
            validate={validateProduct}
            emptyObject={emptyObject}
            setValidationErrors={setValidationErrors}
            name={'Product'}
        />
    );
}

//CREATE hook (post new product to api)
function useCreateProduct(setError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (product) => {
            return await httpClient
                .post('/product', product)
                .then((result) => {
                    if (result.status === 200) {
                        return true;
                    }
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        //client side optimistic update
        onMutate: (newProductInfo) => {
            queryClient.setQueryData([PRODUCT_QUERY_KEY], (prevProducts) => [
                {
                    ...newProductInfo,
                    price: { $numberDecimal: newProductInfo.price },
                    _id: (Math.random() + 1).toString(36).substring(7),
                },
                ...prevProducts,
            ]);
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [PRODUCT_QUERY_KEY] }),
    });
}

//READ hook (get products from api)
function useGetProducts() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [PRODUCT_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get('/product').then((result) => {
                if (result.status === 200) {
                    return result.data.reverse();
                }
            });
        },
    });
}

//UPDATE hook (put product in api)
function useUpdateProduct(setError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (product) => {
            return await httpClient
                .put('/product', product)
                .then((result) => {
                    return true;
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        //client side optimistic update
        onMutate: (newProductInfo) => {
            queryClient.setQueryData([PRODUCT_QUERY_KEY], (prevProducts) =>
                prevProducts?.map((prevProduct) =>
                    prevProduct._id === newProductInfo._id
                        ? {
                              ...newProductInfo,
                              price: { $numberDecimal: newProductInfo.price },
                          }
                        : prevProduct
                )
            );
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [PRODUCT_QUERY_KEY] }),
    });
}

//DELETE hook (delete product in api)
function useDeleteProduct() {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (productId) => {
            return await httpClient
                .delete('/product', { data: { _id: productId } })
                .then((result) => {
                    return result;
                });
        },
        //client side optimistic update
        onMutate: (productId) => {
            queryClient.setQueryData([PRODUCT_QUERY_KEY], (prevProducts) =>
                prevProducts?.filter((product) => product._id !== productId)
            );
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [PRODUCT_QUERY_KEY] }),
    });
}

const validateRequired = (value) => !!value.length;
const validatePrice = (value) => !!value && parseFloat(value) > 0;

function validateProduct(product) {
    return {
        name: !validateRequired(product.name) ? 'Name is Required' : '',
        price: !validatePrice(product.price) ? 'Price needs to be more than 0' : '',
    };
}

export default ProductTable;
