import { AccountBalanceWallet, AssignmentTurnedIn, Edit } from '@mui/icons-material';
import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Button,
    Divider,
    Popover,
    TextField,
} from '@mui/material';

import './CashRegistry.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHttpClient } from '../../provider/HttpClientProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    CASH_REGISTRY_BALANCE_QUERY_KEY,
    PRODUCT_SALE_BALANCE_QUERY_KEY,
    RENTAL_SESSION_SALE_BALANCE_QUERY_KEY,
} from '../../shared/utils/queryConstants';
import { useOrgOptions } from '../../provider/OrganizationOptionsProvider';

function CashRegistry({ cashRegistryBalance, setError }) {
    const [isEndDay, setIsEndDay] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [cashRegistry, setCashRegistry] = useState({
        currentBalance: cashRegistryBalance,
        modifiedAmount: 0,
        updatedBalance: cashRegistryBalance,
        note: '',
        confirm: '',
    });
    const { currency } = useOrgOptions();

    const [isConfirmed, setIsConfirmed] = useState(false);

    const { mutateAsync: createRegistryEvent } = useCreateCashRegistryEvent(setError);

    useEffect(() => {
        setCashRegistry((curState) => {
            return { ...curState, modifiedAmount: 0 };
        });
    }, [isEndDay]);

    useEffect(() => {
        setCashRegistry((curState) => {
            return { ...curState, currentBalance: cashRegistryBalance };
        });
    }, [cashRegistryBalance]);

    useEffect(() => {
        const updatedBalance =
            parseFloat(cashRegistryBalance) +
            (isEndDay
                ? -parseFloat(cashRegistry.modifiedAmount)
                : parseFloat(cashRegistry.modifiedAmount));

        setCashRegistry((curState) => {
            return { ...curState, updatedBalance: updatedBalance };
        });
    }, [cashRegistry.modifiedAmount, cashRegistryBalance, isEndDay]);

    useEffect(() => {
        if (isEndDay && cashRegistry.updatedBalance < 0) {
            setCashRegistry((curState) => {
                return { ...curState, updatedBalance: 0 };
            });
        }
    }, [cashRegistry.updatedBalance, isEndDay]);

    const handleClick = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        setIsConfirmed(false);
        setCashRegistry((curState) => {
            return { ...curState, confirm: '' };
        });
    }, [setAnchorEl, setIsConfirmed, setCashRegistry]);

    const handleModifyAmountChange = useCallback(
        (e) => {
            let value = e.target.value?.replace(/^0+/, '').replace(/^-0+/, '-');

            if (!e.target.value || !value || value === '-') {
                if (!isEndDay && e.nativeEvent.data === '-') {
                    value = '-0';
                } else {
                    value = '0';
                }

                setCashRegistry((curState) => {
                    return { ...curState, modifiedAmount: value };
                });
                return;
            }

            value = Math.max(parseFloat(value), isEndDay ? 0 : -parseFloat(cashRegistryBalance));

            setCashRegistry((curState) => {
                return { ...curState, modifiedAmount: value.toString() };
            });
        },
        [isEndDay, setCashRegistry, cashRegistryBalance]
    );

    const handleNoteAndConfirmChange = useCallback(
        (e) => {
            setCashRegistry((curState) => {
                return { ...curState, [e.target.name]: e.target.value };
            });
        },
        [setCashRegistry]
    );

    useEffect(() => {
        setIsConfirmed(cashRegistry.confirm === 'CONFIRM');
    }, [cashRegistry.confirm]);

    const createEvent = useCallback(
        (e) => {
            e.preventDefault();
            const updatedBalance = isEndDay ? 'withdrewAmount' : 'updatedBalance';
            createRegistryEvent({
                type: isEndDay ? 'END_DAY' : 'EDIT',
                timestamp: Date.now(),
                message: cashRegistry.note,
                value: {
                    value: parseFloat(cashRegistry.modifiedAmount),
                    currentBalance: parseFloat(cashRegistry.currentBalance),
                    [updatedBalance]: parseFloat(cashRegistry.updatedBalance),
                },
            }).then((result) => {
                if (result) {
                    setAnchorEl(null);
                    setCashRegistry({
                        currentBalance: cashRegistryBalance,
                        modifiedAmount: 0,
                        updatedBalance: cashRegistryBalance,
                        note: '',
                        confirm: '',
                    });
                }
            });
        },
        [createRegistryEvent, isEndDay, cashRegistry, cashRegistryBalance]
    );

    const open = useMemo(() => {
        return Boolean(anchorEl);
    }, [anchorEl]);
    const id = useMemo(() => {
        return open ? 'simple-popover' : undefined;
    }, [open]);

    return (
        <>
            <div className="cashRegistry-wrapper">
                <Button
                    aria-describedby={id}
                    color="error"
                    variant="outlined"
                    onClick={handleClick}>
                    <AccountBalanceWallet />
                </Button>

                <Popover
                    className="cashRegistry-popover-wrapper"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <Box>
                        <BottomNavigation
                            showLabels
                            value={isEndDay}
                            onChange={(event, newValue) => {
                                setIsEndDay(newValue);
                            }}>
                            <BottomNavigationAction
                                sx={{
                                    '&.Mui-selected': {
                                        color: 'var(--secondary-blue)',
                                    },
                                }}
                                value={false}
                                label="Edit"
                                icon={<Edit />}
                            />
                            <BottomNavigationAction
                                sx={{
                                    '&.Mui-selected': {
                                        color: 'var(--secondary-blue)',
                                    },
                                }}
                                value={true}
                                label="END DAY"
                                icon={<AssignmentTurnedIn />}
                            />
                        </BottomNavigation>
                    </Box>

                    <Divider />
                    <Box component={'form'} className="cashRegistry-body">
                        <div className="cashRegistry-body-item">
                            <TextField
                                id="standard-basic"
                                label="Current Balance"
                                variant="standard"
                                value={cashRegistry.currentBalance + currency.sign}
                                InputLabelProps={{ shrink: true }}
                                disabled
                            />
                        </div>
                        {isEndDay ? (
                            <>
                                <div className="cashRegistry-body-item">
                                    <TextField
                                        id="standard-basic"
                                        label="Starting Balance"
                                        variant="standard"
                                        type="number"
                                        value={cashRegistry.modifiedAmount}
                                        onChange={handleModifyAmountChange}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                                <div className="cashRegistry-body-item">
                                    <TextField
                                        id="standard-basic"
                                        label="Withdrew  Amount"
                                        variant="standard"
                                        value={
                                            parseFloat(cashRegistry.updatedBalance).toFixed(2) +
                                            currency.sign
                                        }
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="cashRegistry-body-item">
                                    <TextField
                                        id="standard-basic"
                                        label="Edit Amount"
                                        type="number"
                                        variant="standard"
                                        value={cashRegistry.modifiedAmount}
                                        InputProps={{ inputProps: { min: -cashRegistryBalance } }}
                                        onChange={handleModifyAmountChange}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                                <div className="cashRegistry-body-item">
                                    <TextField
                                        id="standard-basic"
                                        label="New Balance"
                                        variant="standard"
                                        value={
                                            parseFloat(cashRegistry.updatedBalance).toFixed(2) +
                                            currency.sign
                                        }
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                    />
                                </div>
                            </>
                        )}
                        <div className="cashRegistry-body-item">
                            <TextField
                                id="standard-basic"
                                label="Add Note"
                                variant="standard"
                                value={cashRegistry.note}
                                name="note"
                                onChange={handleNoteAndConfirmChange}
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                        </div>
                        <div className="cashRegistry-body-item">
                            <TextField
                                id="standard-basic"
                                label='confirm by typing "CONFIRM"'
                                variant="standard"
                                name="confirm"
                                value={cashRegistry.confirm}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleNoteAndConfirmChange}
                                required
                                color="success"
                                error={!isConfirmed}
                            />
                        </div>
                        <Divider />

                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            disabled={!isConfirmed}
                            onClick={createEvent}>
                            {isEndDay ? 'End Day' : 'Edit'}
                        </Button>
                    </Box>
                </Popover>
            </div>
        </>
    );
}

function useCreateCashRegistryEvent(setError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (registryEvent) => {
            return await httpClient
                .post('/organizationEvent/cashRegistry', registryEvent)
                .then((result) => {
                    if (result.status === 200) {
                        return true;
                    }
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_SALE_BALANCE_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [PRODUCT_SALE_BALANCE_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [CASH_REGISTRY_BALANCE_QUERY_KEY] });
        },
    });
}

export default CashRegistry;
