import './RentalSessionCard.css';
import { forwardRef } from 'react';
import RentalSessionCard from './SessionCard/RentalSessionCard';

const RentalSessionsCategoryCards = forwardRef(
    ({ rentalSessions = {}, rentals, rentalCategory, onSessionExpire, onError }, ref) => {
        return (
            <div className="rentalSessionCards-wrapper">
                {rentals.sort(sortByName).map((rental) => (
                    <RentalSessionCard
                        key={'CATEGORY_CARD_' + rental._id}
                        liveSession={rentalSessions[rental._id]}
                        rental={rental}
                        rentalCategory={rentalCategory}
                        onError={onError}
                        onSessionExpire={onSessionExpire}
                        ref={(el) => {
                            return (ref.current[rental._id] = el);
                        }}
                    />
                ))}
            </div>
        );
    }
);

export default RentalSessionsCategoryCards;

const sortByName = (a, b) => {
    const reA = /[^a-zA-Z]/g;
    const reN = /[^0-9]/g;

    const nameA = a.name.toUpperCase().replace(reA, '');
    const nameB = b.name.toUpperCase().replace(reA, '');

    if (nameA === nameB) {
        let aN = parseInt(a.name.replace(reN, ''), 10);
        let bN = parseInt(b.name.replace(reN, ''), 10);

        aN = aN ? aN : 0;
        bN = bN ? bN : 0;

        return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
        return nameA > nameB ? 1 : -1;
    }
};
