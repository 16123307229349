import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../provider/AuthProvider';
import Navbar from '../shared/Navbar/Navbar';
import Footer from '../shared/Footer/Footer';
import './ProtectedRoute.css';
import OrganizationOptionsProvider from '../provider/OrganizationOptionsProvider';

export const ProtectedRoute = () => {
    const { token } = useAuth();

    // Check if the user is authenticated
    if (!token) {
        // If not authenticated, redirect to the login page
        return <Navigate to="/login" />;
    }

    // If authenticated, render the child routes
    return (
        <OrganizationOptionsProvider>
            <div className="protected-route-wrapper">
                <Navbar />
                <div className="protected-route-main">
                    <Outlet />
                </div>
                <Footer />
            </div>
        </OrganizationOptionsProvider>
    );
};
