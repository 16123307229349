import ActiveRentalSessionCard from './ActiveCard/ActiveRentalSessionCard';
import InactiveRentalSessionCard from './InactiveCard/InactiveRentalSessionCard';
import { forwardRef } from 'react';

const RentalSessionCard = forwardRef(
    ({ liveSession, rental, rentalCategory, onSessionExpire, onError }, ref) => {
        return (
            <div className="rentalCard-wrapper" ref={ref}>
                {liveSession ? (
                    <ActiveRentalSessionCard
                        key={rental._id}
                        session={liveSession}
                        onError={onError}
                        updateExpiredSessions={onSessionExpire}
                    />
                ) : (
                    <InactiveRentalSessionCard
                        key={rental._id}
                        rental={rental}
                        rentalCategory={rentalCategory}
                        setError={onError}
                    />
                )}
            </div>
        );
    }
);

export default RentalSessionCard;
