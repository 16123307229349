import { Box, IconButton, Tooltip } from '@mui/material';
import { MaterialReactTable, createRow, useMaterialReactTable } from 'material-react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

const emptyObject = { name: '', hourlyRateIncrease: { $numberDecimal: 0 } };

// TODO Add validation for unique name
function RentalTypeInput({ rentalTypes = [], onChange }) {
    const [validationErrors, setValidationErrors] = useState({});
    const [curRentalTypes, setCurRentalTypes] = useState(rentalTypes);

    useEffect(() => {
        onChange(curRentalTypes);
    }, [curRentalTypes, onChange]);

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                size: 140,
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.name,
                    helperText: validationErrors?.name,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            name: undefined,
                        }),
                },
            },
            {
                header: 'Rate Increase',
                id: 'hourlyRateIncrease',
                accessorFn: (dataRow) => parseFloat(dataRow?.hourlyRateIncrease?.$numberDecimal),
                size: 130,
                muiEditTextFieldProps: {
                    type: 'number',
                    required: true,
                    error: !!validationErrors?.hourlyRateIncrease,
                    helperText: validationErrors?.hourlyRateIncrease,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            hourlyRateIncrease: undefined,
                        }),
                },
            },
        ],
        [validationErrors]
    );

    //CREATE action
    const handleCreateRentalType = useCallback(async ({ values, table }) => {
        const newValidationErrors = validateRentalType(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }

        setValidationErrors({});
        setCurRentalTypes((curTypes) => [
            {
                ...values,
                hourlyRateIncrease: { $numberDecimal: values.hourlyRateIncrease },
                tempId: (Math.random() + 1).toString(36).substring(7),
            },
            ...curTypes,
        ]);
        table.setCreatingRow(null);
    }, []);

    //UPDATE action
    const handleSaveRentaType = useCallback(async ({ values, row, table }) => {
        const newValidationErrors = validateRentalType(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }

        setValidationErrors({});
        setCurRentalTypes((curTypes) =>
            curTypes.map((type) =>
                type._id === row.id || type.tempId === row.id
                    ? {
                          ...values,
                          _id: row.original._id,
                          hourlyRateIncrease: {
                              $numberDecimal: values.hourlyRateIncrease.toString(),
                          },
                      }
                    : { ...type }
            )
        );
        table.setEditingRow(null);
    }, []);

    //DELETE action
    const openDeleteConfirmModal = useCallback((row) => {
        if (window.confirm('Are you sure you want to delete this rental type?')) {
            setCurRentalTypes((curTypes) => {
                return curTypes.filter((type) => type._id !== row.id && type.tempId !== row.id);
            });
        }
    }, []);

    const table = useMaterialReactTable({
        columns,

        data: curRentalTypes,

        enableColumnOrdering: false,

        enableDensityToggle: false,

        enablePagination: false,

        enableEditing: true,

        enableRowActions: true,

        enableColumnFilters: false,

        enableSorting: false,

        enableColumnFilterModes: false,

        enableHiding: false,

        enableFullScreenToggle: false,

        enableColumnActions: false,

        enableBottomToolbar: false,

        positionActionsColumn: 'last',

        createDisplayMode: 'row',

        editDisplayMode: 'row',

        layoutMode: 'grid-no-grow',

        getRowId: (row) => (row.tempId ? row.tempId : row._id),

        onCreatingRowCancel: () => setValidationErrors({}),

        onCreatingRowSave: handleCreateRentalType,

        onEditingRowCancel: () => setValidationErrors({}),

        onEditingRowSave: handleSaveRentaType,

        muiTableHeadCellProps: {},

        muiTableBodyRowProps: {
            hover: false,
            sx: {
                // height: '6vh',
            },
        },

        muiTableContainerProps: {
            sx: {
                overflowY: 'scroll',
                height: '30vh',
            },
        },

        muiSearchTextFieldProps: {
            sx: {
                width: '10rem',
            },
        },

        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),

        renderTopToolbarCustomActions: ({ table }) => (
            <IconButton
                variant="contained"
                onClick={() => {
                    table.setCreatingRow(createRow(table, emptyObject));
                }}>
                <AddIcon style={{ color: 'green' }} />
                <span style={{ marginLeft: '10px', fontSize: '0.9rem', color: 'black' }}>
                    Add Rental Type
                </span>
            </IconButton>
        ),

        defaultColumn: {
            minSize: 105,
            maxSize: 150,
        },

        state: {},
    });

    return <MaterialReactTable table={table} />;
}

const validateRequired = (value) => !!value.length;

function validateRentalType(rentalType) {
    return {
        name: !validateRequired(rentalType.name) ? 'Required' : '',
        hourlyRateIncrease: !validateRequired(rentalType.hourlyRateIncrease) ? 'Required' : '',
    };
}

export default RentalTypeInput;
