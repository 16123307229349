import { useMemo, useState } from 'react';
import { useHttpClient } from '../provider/HttpClientProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import TableWrapper from '../shared/TableWrapper/TableWrapper';
import { USER_QUERY_KEY } from '../shared/utils/queryConstants';
import { MenuItem } from '@mui/material';

const emptyObject = { username: '', role: '', active: '', password: '' };

function UserTable() {
    const [validationErrors, setValidationErrors] = useState({});

    const columns = useMemo(
        () => [
            {
                header: 'Username',
                accessorKey: 'username',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.username,
                    helperText: validationErrors?.username,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            username: undefined,
                        }),
                },
            },
            {
                header: 'Password',
                accessorKey: 'password',
                muiEditTextFieldProps: {
                    required: false,
                    type: 'password',
                    error: !!validationErrors?.password,
                    helperText: validationErrors?.password,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            password: undefined,
                        }),
                },
                enableHiding: false,
            },
            {
                header: 'Role',
                accessorKey: 'role',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.role,
                    helperText: validationErrors?.role,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            role: undefined,
                        }),
                    children: [
                        <MenuItem key={'ADMIN'} value={'ADMIN'}>
                            Admin
                        </MenuItem>,
                        <MenuItem key={'AGENT'} value={'AGENT'}>
                            Agent
                        </MenuItem>,
                    ],
                    select: true,
                },
            },
            {
                header: 'Active',
                id: 'active',
                accessorFn: (dataRow) => (dataRow.active ? 'true' : 'false'),
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.active,
                    helperText: validationErrors?.active,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            active: undefined,
                        }),
                    children: [
                        <MenuItem key={'true'} value={'true'}>
                            True
                        </MenuItem>,
                        <MenuItem key={'false'} value={'false'}>
                            False
                        </MenuItem>,
                    ],
                    select: true,
                },
            },
        ],
        [validationErrors]
    );
    return (
        <TableWrapper
            columns={columns}
            useCreate={useCreateUser}
            useUpdate={useUpdateUser}
            useGet={useGetUser}
            validate={validateUser}
            emptyObject={emptyObject}
            setValidationErrors={setValidationErrors}
            name={'User'}
            initialState={{
                columnVisibility: { password: false },
            }}
        />
    );
}

//CREATE hook (post new user to api)
function useCreateUser(setError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (user) => {
            return await httpClient
                .post('/user', user)
                .then((result) => {
                    if (result.status === 200) {
                        return true;
                    }
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        //client side optimistic update
        onMutate: (newUserInfo) => {
            queryClient.setQueryData([USER_QUERY_KEY], (prevUsers) => [
                {
                    ...newUserInfo,
                    _id: (Math.random() + 1).toString(36).substring(7),
                },
                ...prevUsers,
            ]);
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] }),
    });
}

//READ hook (get users from api)
function useGetUser() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [USER_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get('/user').then((result) => {
                if (result.status === 200) {
                    return result.data.reverse();
                }
            });
        },
    });
}

//UPDATE hook (put user in api)
function useUpdateUser(setError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (user) => {
            return await httpClient
                .put('/user', user)
                .then((result) => {
                    return true;
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        //client side optimistic update
        onMutate: (newUserInfo) => {
            queryClient.setQueryData([USER_QUERY_KEY], (prevUsers) =>
                prevUsers?.map((prevUser) =>
                    prevUser._id === newUserInfo._id
                        ? {
                              ...newUserInfo,
                          }
                        : prevUser
                )
            );
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] }),
    });
}

const validateRequired = (value) => !!value.length;

function validateUser(user) {
    return {
        username: !validateRequired(user.username) ? 'Username is required' : '',
        role: !validateRequired(user.role) ? 'Role is required' : '',
        active: !validateRequired(user.active) ? 'Status is required' : '',
    };
}

export default UserTable;
