import RentalSessionsWrapperProvider from '../provider/RentalSessionsWrapperProvider';
import RentalSessions from './RentalSessions';

function RentalSessionsWrapper() {
    return (
        <RentalSessionsWrapperProvider>
            <RentalSessions />
        </RentalSessionsWrapperProvider>
    );
}

export default RentalSessionsWrapper;
