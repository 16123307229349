import axios from 'axios';
import { createContext, useContext, useMemo } from 'react';
import { useAuth } from './AuthProvider';

const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? 'http://localhost:8000/';

const createHttpClient = (token) => {
    const headers = {};

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    return axios.create({
        baseURL: SERVER_URL,
        headers,
    });
};

export const HttpClientContext = createContext(createHttpClient(null));
export const HttpClientProvider = ({ children }) => {
    const { token } = useAuth();
    // Recreate the client everytime the token changes.
    const httpClient = useMemo(() => createHttpClient(token), [token]);

    return <HttpClientContext.Provider value={httpClient}>{children}</HttpClientContext.Provider>;
};

/**
 * HTTP client that takes the auth token provided by AuthContext
 * and sends it on every request.
 *
 * @returns {import('axios').AxiosInstance}
 */
export const useHttpClient = () => useContext(HttpClientContext);
