import { AddLink, Edit, MoreHoriz, SwapVert } from '@mui/icons-material';
import {
    ClickAwayListener,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import './SessionMenu.css';
import RentalSessionEditPopup from '../RentalSessionPopUps/EditPopUp/RentalSessionEditPopUp';
import RentalSessionTransferPopUp from '../RentalSessionPopUps/TransferPopUp/RentalSessionTransferPopUp';
import LinkRentalSession from '../RentalSessionPopUps/LinkPopup/LinkRentalSession';
import { useAuth } from '../../provider/AuthProvider';

function SessionMenu({ session, onEdit, onTransfer, onLink, onError }) {
    const user = useAuth();
    const [open, setOpen] = useState(false);
    const [openPopUps, setOpenPopUps] = useState({
        edit: false,
        transfer: false,
        link: false,
    });

    const anchorRef = useRef(null);
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleToggle = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, [setOpen]);

    const handleClose = useCallback(
        (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
            }

            setOpen(false);
        },
        [anchorRef, setOpen]
    );

    const handleListKeyDown = useCallback(
        (event) => {
            if (event.key === 'Tab') {
                event.preventDefault();
                setOpen(false);
            } else if (event.key === 'Escape') {
                setOpen(false);
            }
        },
        [setOpen]
    );

    const handlePopUpOpenStateChange = useCallback(
        (name, isOpen) => {
            setOpenPopUps((curState) => ({ ...curState, [name]: isOpen }));
        },
        [setOpenPopUps]
    );

    const handleMenuItemClick = useCallback(
        (e) => {
            handlePopUpOpenStateChange(e.target.id, true);
            handleClose(e);
        },
        [handlePopUpOpenStateChange, handleClose]
    );

    return (
        <div style={{ display: 'inline-block' }}>
            <IconButton
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>
                <MoreHoriz />
            </IconButton>
            <Popper
                className="SessionMenu-popper"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}>
                        <Paper className="SessionMenu-paper">
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}>
                                    <MenuItem
                                        className="SessionMenu-item"
                                        id="edit"
                                        onClick={handleMenuItemClick}>
                                        Edit
                                        <Edit fontSize="small" style={{ pointerEvents: 'none' }} />
                                    </MenuItem>
                                    <MenuItem
                                        className="SessionMenu-item"
                                        id="transfer"
                                        onClick={handleMenuItemClick}>
                                        Transfer
                                        <SwapVert style={{ pointerEvents: 'none' }} />
                                    </MenuItem>
                                    {user.userInfo.orgId === '56cb91bdc3464f14678934ca' ? (
                                        <MenuItem
                                            className="SessionMenu-item"
                                            id="link"
                                            onClick={handleMenuItemClick}>
                                            Link
                                            <AddLink style={{ pointerEvents: 'none' }} />
                                        </MenuItem>
                                    ) : null}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            {openPopUps.edit ? (
                <RentalSessionEditPopup
                    onOpenStateChange={(isOpen) => handlePopUpOpenStateChange('edit', isOpen)}
                    session={session}
                    onEdit={onEdit}
                    onError={onError}
                />
            ) : null}
            {openPopUps.transfer ? (
                <RentalSessionTransferPopUp
                    onOpenStateChange={(isOpen) => handlePopUpOpenStateChange('transfer', isOpen)}
                    session={session}
                    onTransfer={onTransfer}
                />
            ) : null}
            {openPopUps.link ? (
                <LinkRentalSession
                    onOpenStateChange={(isOpen) => handlePopUpOpenStateChange('link', isOpen)}
                    session={session}
                    onLink={onLink}
                />
            ) : null}
        </div>
    );
}

export default SessionMenu;
