import { Alert as MuiAlert } from '@mui/material';
import { Stack } from '@mui/material';
import './ExpiredAlert.css';
import { useEffect, useMemo, useState } from 'react';
import { FLASHING_INTERVAL } from '../timers/TimerConstants';
import { VolumeOff, VolumeUp } from '@mui/icons-material';
import { useStopwatch } from 'react-timer-hook';
import { calculateOffset, formatTime } from '../rentalSessionUtils';

function ExpiredAlert({ expiredSessions, handleClick }) {
    const [isFlashing, setIsFlashing] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const [savedExpiredSessions, setSavedExpiredSessions] = useState({ ...expiredSessions });

    const expiredAlertAudio = useMemo(() => {
        return new Audio('alertSound.mp3');
    }, []);

    useEffect(() => {
        for (let key of Object.keys(expiredSessions)) {
            if (!savedExpiredSessions[key]) {
                setIsMuted(false);
                break;
            }
        }

        setSavedExpiredSessions(expiredSessions);

        if (Object.keys(expiredSessions).length) {
            const interval = setInterval(() => {
                setIsFlashing((curState) => !curState);
            }, FLASHING_INTERVAL);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expiredSessions]);

    useEffect(() => {
        const shouldAudioPlay = () => {
            return !isMuted && Object.keys(expiredSessions).length;
        };

        if (shouldAudioPlay()) {
            const interval = setInterval(() => {
                if (shouldAudioPlay()) {
                    expiredAlertAudio.play();
                }
            }, FLASHING_INTERVAL);
            return () => clearInterval(interval);
        }
    }, [isMuted, expiredSessions, expiredAlertAudio]);

    if (Object.keys(expiredSessions).length) {
        return (
            <MuiAlert
                variant="outlined"
                severity="warning"
                className={`expiredSessions-container ` + (isFlashing ? 'white' : 'red')}>
                {isMuted ? (
                    <VolumeOff
                        className="expiredSessions-toggleVolume"
                        onClick={() => setIsMuted(false)}
                    />
                ) : (
                    <VolumeUp
                        className="expiredSessions-toggleVolume"
                        onClick={() => setIsMuted(true)}
                    />
                )}
                <span>{Object.keys(expiredSessions).length} Session(s) has been expired</span>
                <Stack spacing={3} className="expiredSessions-stack">
                    {Object.keys(expiredSessions).map((rentalId) => (
                        <AlertItem
                            expiredSession={expiredSessions[rentalId]}
                            handleClick={handleClick}
                            key={rentalId}
                        />
                    ))}
                </Stack>
            </MuiAlert>
        );
    } else {
        return null;
    }
}

function AlertItem({ expiredSession, handleClick }) {
    const { seconds, minutes, hours } = useStopwatch({
        autoStart: true,
        offsetTimestamp: calculateOffset(expiredSession.endTimestamp),
    });
    return (
        <MuiAlert
            onClick={() => handleClick(expiredSession)}
            className="expiredSessions-item"
            variant="filled"
            severity="error">
            <div style={{ fontSize: '1.2rem' }}>
                {expiredSession.rentalName}
                <div>
                    <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:
                    <span>{formatTime(seconds)}</span>
                </div>
            </div>
        </MuiAlert>
    );
}

export default ExpiredAlert;
