import { useCallback, useEffect, useState } from 'react';
import TimeInput from '../../../shared/TimeInput/TimeInput';
import './RentalSessionAdditionalTimePopUp.css';
import '../RentalSessionPopUps.css';
import { Backdrop, CircularProgress } from '@mui/material';
import { calculatePrice } from '../../rentalSessionUtils';
import PaymentMethodInputs from '../../../shared/PaymentMethodInputs/PaymentMethodInputs';
import { useOrgOptions } from '../../../provider/OrganizationOptionsProvider';
import { useRentalCategories } from '../../../provider/RentalSessionsWrapperProvider';

function RentalSessionAdditionalTimePopUp({
    setAdditionalTimePopUp,
    handleAddTime,
    rentalTypes = [],
    prepaidBalance,
    totalPrice,
    curDuration,
    transferHistory,
    categoryId,
}) {
    const [duration, setDuration] = useState(0);
    const [payments, setPayments] = useState({
        cash: 0,
        credit: 0,
    });
    const [price, setPrice] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { currency } = useOrgOptions();
    const { categoriesMap } = useRentalCategories();

    useEffect(() => {
        const calculatePriceDifference = (duration) => {
            return (
                parseFloat(
                    calculatePrice(
                        curDuration + duration,
                        categoryId,
                        rentalTypes,
                        transferHistory,
                        categoriesMap
                    )
                ) -
                parseFloat(
                    calculatePrice(
                        curDuration,
                        categoryId,
                        rentalTypes,
                        transferHistory,
                        categoriesMap
                    )
                )
            );
        };

        setPrice(calculatePriceDifference(duration).toFixed(2));
    }, [
        duration,
        rentalTypes,
        totalPrice,
        curDuration,
        categoryId,
        transferHistory,
        categoriesMap,
    ]);

    const handlePaymentsInput = useCallback(
        (paymentInputs) => {
            setPayments({ ...paymentInputs });
        },
        [setPayments]
    );

    const handleCancle = useCallback(() => {
        if (!isLoading) {
            setAdditionalTimePopUp(false);
        }
    }, [setAdditionalTimePopUp, isLoading]);

    const handleAdd = useCallback(async () => {
        setIsLoading(true);
        if (await handleAddTime(duration, payments.cash, payments.credit)) {
            setIsLoading(false);
            setAdditionalTimePopUp(false);
        }
    }, [setAdditionalTimePopUp, handleAddTime, duration, payments.cash, payments.credit]);

    return (
        <>
            <div className="popUp-wrapper rentalSession-additionalTime">
                <div className="additionalTime-prices">
                    <div>
                        <label htmlFor="curPrice">Expected Price:</label>
                        <input
                            type="text"
                            id="curPrice"
                            value={parseFloat(totalPrice).toFixed(2) + currency.sign}
                            disabled
                            className="priceInputs"
                        />
                    </div>
                    <div>
                        <label htmlFor="curPrice">Prepaid Balance:</label>
                        <input
                            type="text"
                            id="curPrice"
                            value={prepaidBalance.totalPrepaid.toFixed(2) + currency.sign}
                            disabled
                            className="priceInputs"
                        />
                    </div>
                    <div className="additionalPrice-timeInput-container">
                        <label htmlFor="timeInput">Additional Time:</label>
                        <TimeInput onChange={setDuration} />
                    </div>

                    <div>
                        <label htmlFor="additionalPrice">Additional Price:</label>
                        <input
                            type="text"
                            id="additionalPrice"
                            value={price + currency.sign}
                            disabled
                            className="priceInputs"
                        />
                    </div>
                    <div>
                        <PaymentMethodInputs
                            maxTotalValue={
                                parseFloat(totalPrice) -
                                parseFloat(prepaidBalance.totalPrepaid) +
                                parseFloat(price)
                            }
                            onInput={handlePaymentsInput}
                            strictMode={false}
                        />
                    </div>
                </div>
                <div className="additionalTime-footer">
                    <div className="additionalTime-totalPrice-container">
                        <label htmlFor="futurePrice" className="additionalTime-totalPriceLabel">
                            Total Price:
                        </label>
                        <input
                            type="text"
                            className="priceInputs"
                            id="futurePrice"
                            value={
                                (
                                    parseFloat(totalPrice) +
                                    parseFloat(price) -
                                    parseFloat(prepaidBalance.totalPrepaid) -
                                    payments.cash -
                                    payments.credit
                                ).toFixed(2) + currency.sign
                            }
                            disabled
                        />
                    </div>

                    <div className="sessionPopUp-actions additionalTime-actions">
                        <button
                            onClick={handleCancle}
                            disabled={isLoading}
                            className="popUp-button cancel">
                            Cancel
                        </button>
                        <button
                            onClick={handleAdd}
                            disabled={isLoading}
                            className="popUp-button start">
                            Add
                            {isLoading ? <CircularProgress size={'1rem'} color="inherit" /> : null}
                        </button>
                    </div>
                </div>
            </div>
            <Backdrop className="alert-backdrop" open={true} onClick={handleCancle} />
        </>
    );
}

export default RentalSessionAdditionalTimePopUp;
