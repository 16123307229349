import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useHttpClient } from '../../provider/HttpClientProvider';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { CASH_REGISTRY_QUERY_KEY, USER_QUERY_KEY } from '../utils/queryConstants';
import Alert from '../Alert/Alert';
import { convertDateRangeToTimestamp } from '../utils/timestampUtils';

const emptyObj = {
    docs: [],
};

const emptyArray = [];

function CashRegistryHistoryTable() {
    const { data: users = emptyArray } = useGetUsers();
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [dateRange, setDateRange] = useState({
        startDate: dayjs(Date.now()),
        endDate: dayjs(Date.now()),
    });
    const [error, setError] = useState();

    const {
        data = emptyObj,
        isError: isCashRegistryError,
        isFetching: isCashRegistryFetching,
        isLoading: isCashRegistryLoading,
        isPreviousData,
    } = useGetCashRegisrty(pagination.pageIndex, pagination.pageSize, fetchData, dateRange);

    useEffect(() => {
        if (!isPreviousData && data?.hasNextPage) {
            queryClient.prefetchQuery({
                queryKey: [
                    CASH_REGISTRY_QUERY_KEY,
                    pagination.pageIndex + 1,
                    pagination.pageSize,
                    dateRange,
                ],
                queryFn: () =>
                    fetchData(httpClient, pagination.pageIndex + 1, pagination.pageSize, dateRange),
            });
        }
    }, [
        data,
        isPreviousData,
        dateRange,
        pagination.pageSize,
        pagination.pageIndex,
        queryClient,
        httpClient,
    ]);

    const handleDatePick = useCallback(
        (value, targetedDate) => {
            setDateRange((curDateRange) => ({ ...curDateRange, [targetedDate]: value }));
        },
        [setDateRange]
    );

    const usernamesMap = useMemo(
        () =>
            users.reduce((curMap, user) => {
                return { ...curMap, [user._id]: user.username };
            }, {}),
        [users]
    );

    const columns = useMemo(
        () => [
            {
                header: 'Username',
                accessorFn: (data) => usernamesMap[data.userId] ?? data.userId,
            },
            {
                header: 'Type',
                accessorKey: 'type',
            },
            {
                header: 'Starting Balance',
                accessorFn: (balance) => {
                    return parseFloat(balance.value.currentBalance).toFixed(2);
                },
            },
            {
                header: 'Change',
                accessorFn: (data) =>
                    parseFloat(
                        data.type !== 'END_DAY' ? data.value.value : -data.value.withdrewAmount
                    ).toFixed(2),
            },
            {
                header: 'Ending Balance',
                accessorFn: (data) =>
                    parseFloat(
                        data.type !== 'END_DAY' ? data.value.updatedBalance : data.value.value
                    ).toFixed(2),
            },
            {
                header: 'Date',
                accessorFn: (dataRow) =>
                    new Date(parseInt(dataRow.timestamp)).toLocaleString('it-IT'),
            },
            {
                header: 'Note',
                accessorKey: 'message',
            },
        ],
        [usernamesMap]
    );

    const table = useMaterialReactTable({
        columns,

        data: data.docs,

        enableColumnOrdering: false,

        enableDensityToggle: false,

        enableColumnFilters: false,

        enableSorting: false,

        enableColumnFilterModes: false,

        enableHiding: false,

        enableColumnActions: false,

        enablePagination: true,

        manualPagination: true,

        enableToolbarInternalActions: false,

        rowCount: data.totalDocs,

        positionPagination: 'both',

        enableBottomToolbar: true,

        positionToolbarDropZone: 'none',

        positionActionsColumn: 'last',

        layoutMode: 'grid',

        getRowId: (row) => row._id,

        muiTableHeadCellProps: {},

        muiTableBodyRowProps: {
            hover: false,
            sx: {
                // height: '6vh',
            },
        },

        muiTableContainerProps: {
            sx: {
                overflowY: 'scroll',
                height: '100vh',
            },
        },

        muiSearchTextFieldProps: {
            sx: {
                width: '10rem',
            },
        },

        muiTopToolbarProps: {
            sx: {
                '& > div:nth-of-type(2)': {
                    width: '100%',
                    flex: '1',
                },
                '&': {
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0.2rem 0',
                },
            },
        },

        muiPaginationProps: {
            sx: {
                margin: '0',
            },
        },

        onPaginationChange: setPagination,

        renderTopToolbarCustomActions: () => (
            <div className="topTolbarCustomActions-wrapper">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <DatePicker
                            name="startDate"
                            label="Start Date"
                            value={dateRange.startDate}
                            onChange={(newValue) => handleDatePick(newValue, 'startDate')}
                            className="datePicker"
                        />

                        <DatePicker
                            name="startDate"
                            label="End Date"
                            value={dateRange.endDate}
                            onChange={(newValue) => handleDatePick(newValue, 'endDate')}
                            className="datePicker"
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </div>
        ),

        state: {
            isLoading: isCashRegistryLoading,
            showAlertBanner: isCashRegistryError,
            showProgressBars: isCashRegistryFetching,
            pagination,
        },

        initialState: {
            expanded: false,
        },
    });

    return (
        <>
            <MaterialReactTable table={table} />
            <Alert message={error} setMessage={setError} />
        </>
    );
}

export default CashRegistryHistoryTable;

const fetchData = async (httpClient, pageIndex, pageSize, dateRange) => {
    return await httpClient
        .get(
            `/organizationEvent/history?page=` +
                (pageIndex + 1) +
                '&pageSize=' +
                pageSize +
                (dateRange.startDate
                    ? '&startDate=' + convertDateRangeToTimestamp(dateRange.startDate.$d)
                    : '') +
                (dateRange.endDate
                    ? '&endDate=' + convertDateRangeToTimestamp(dateRange.endDate.$d)
                    : '')
        )
        .then((result) => {
            if (result.status === 200) {
                return result.data;
            }
        });
};

function useGetCashRegisrty(pageIndex, pageSize, fetchData, dateRange) {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [CASH_REGISTRY_QUERY_KEY, pageIndex, pageSize, dateRange],
        queryFn: () => fetchData(httpClient, pageIndex, pageSize, dateRange),
        keepPreviousData: true,
        staleTime: 5000,
    });
}

function useGetUsers() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [USER_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get(`/user`).then((result) => {
                if (result.status === 200) {
                    return result.data;
                }
            });
        },
        refetchInterval: 5 * 60 * 1000,
    });
}
