import { FIXED_SESSION_TYPE } from '../shared/utils/constants';

const ZERO_VALUE = '0.00';

export const calculatePrice = (
    minuteDuration,
    categoryId,
    rentalTypes = [],
    transferHistory,
    categoriesMap
) => {
    if (!transferHistory?.length) {
        return calculateCurrentSessionPrice(
            minuteDuration,
            categoriesMap[categoryId].hourlyRate,
            categoriesMap[categoryId].customRates,
            rentalTypes
        );
    }

    return calculatePriceIncludingTransferHistory(
        minuteDuration,
        categoryId,
        rentalTypes,
        transferHistory,
        categoriesMap
    );
};

export const calculatePriceIncludingTransferHistory = (
    minuteDuration,
    categoryId,
    rentalTypes = [],
    transferHistory = [],
    categoriesMap
) => {
    const msDurationPerCategory = {
        [categoryId]: minuteDuration * 60 * 1000,
    };
    const rentalTypesPricePerCategory = {
        [categoryId]: calculatePriceForRentalTypes(minuteDuration, rentalTypes),
    };

    for (let transfer of transferHistory) {
        const transferCategoryId = transfer.rentalCategoryId;

        if (!msDurationPerCategory[transferCategoryId]) {
            msDurationPerCategory[transferCategoryId] = 0;
            rentalTypesPricePerCategory[transferCategoryId] = 0;
        }

        const segmentMSDuration = calculateSessionMSDuration(
            transfer.startTimestamp,
            transfer.endTimestamp,
            transfer.pauses
        );

        msDurationPerCategory[transferCategoryId] += segmentMSDuration;

        rentalTypesPricePerCategory[transferCategoryId] += calculatePriceForRentalTypes(
            msToMinutes(segmentMSDuration),
            transfer.rentalTypes
        );
    }

    let totalPrice = 0.0;

    for (let curCategoryId of Object.keys(msDurationPerCategory)) {
        const curCategory = categoriesMap[curCategoryId];
        let categoryPrice = rentalTypesPricePerCategory[curCategoryId];

        if (!curCategory) {
            console.error('No category found for categoryId:', curCategoryId);
            continue;
        }

        categoryPrice += calculatePriceForCustomRates(
            msToMinutes(msDurationPerCategory[curCategoryId]),
            curCategory.hourlyRate,
            curCategory.customRates
        );

        totalPrice += categoryPrice > 0 ? categoryPrice : 0;
    }

    return totalPrice > 0 ? totalPrice.toFixed(2) : ZERO_VALUE;
};

export const calculateCurrentSessionPrice = (
    minuteDuration = 0,
    hourlyRate,
    customRates = [],
    rentalTypes = []
) => {
    let totalPrice =
        calculatePriceForCustomRates(minuteDuration, hourlyRate, customRates) +
        calculatePriceForRentalTypes(minuteDuration, rentalTypes);

    return totalPrice > 0 ? totalPrice.toFixed(2) : ZERO_VALUE;
};

export const calculateMinuteDurationForPrice = (
    price = 0,
    hourlyRate,
    customRates = [],
    rentalTypes = []
) => {
    if (!hourlyRate.$numberDecimal) {
        hourlyRate = { $numberDecimal: parseFloat(hourlyRate) };
    }

    const rentalsRate = rentalTypes.reduce(
        (curSum, type) => curSum + parseFloat(type.hourlyRateIncrease.$numberDecimal),
        0.0
    );

    const curCustomRates = [...customRates, { timeUnit: 60, rate: { ...hourlyRate } }].map(
        (customRate) => ({
            timeUnit: customRate.timeUnit,
            rate: {
                $numberDecimal:
                    parseFloat(customRate.rate.$numberDecimal) +
                    (customRate.timeUnit / 60) * rentalsRate,
            },
        })
    );

    let curPrice = price;
    let totalDuration = 0;

    curCustomRates
        .sort((a, b) => {
            return b.rate.$numberDecimal - a.rate.$numberDecimal;
        })
        .forEach((customRate) => {
            totalDuration +=
                Math.floor(curPrice / parseFloat(customRate.rate.$numberDecimal)) *
                customRate.timeUnit;

            curPrice %= parseFloat(customRate.rate.$numberDecimal);
        });

    if (curPrice > 0) {
        totalDuration += Math.floor(
            (curPrice / (parseFloat(hourlyRate.$numberDecimal) + rentalsRate)) * 60
        );
    }

    if (!totalDuration || totalDuration < 0) {
        totalDuration = 0;
    }

    return totalDuration;
};

export const calculateHourlyRate = (hourlyRate, rentalTypes = []) => {
    if (!hourlyRate.$numberDecimal) {
        hourlyRate = { $numberDecimal: parseFloat(hourlyRate) };
    }

    let curHourlyRate = rentalTypes.reduce(
        (curSum, type) => curSum + parseFloat(type.hourlyRateIncrease.$numberDecimal),
        parseFloat(hourlyRate.$numberDecimal)
    );

    if (curHourlyRate < 0) {
        curHourlyRate = 0.0;
    }

    return curHourlyRate.toFixed(2);
};

export const formatTime = (time) => {
    return time < 10 ? '0' + time : time;
};

export const calculateOffset = (startTimestamp) => {
    const curTimestamp = Date.now();
    const timePassed = curTimestamp - startTimestamp;
    return new Date(curTimestamp + timePassed);
};

export const roundValue = (value, orgOptions) => {
    value = parseFloat(value);
    if (orgOptions && orgOptions.rounding) {
        const roundingPoint = orgOptions.rounding.point
            ? parseFloat(orgOptions.rounding.point.$numberDecimal)
            : 0.5;

        switch (orgOptions.rounding.mode) {
            case 'UP':
                return Math.ceil(value / roundingPoint) * roundingPoint;

            case 'DOWN':
                return Math.floor(value / roundingPoint) * roundingPoint;

            case 'ROUND':
            default:
                return Math.round(value / roundingPoint) * roundingPoint;
        }
    }

    return value;
};

export const calculateSessionMSDuration = (startTimestamp, endTimestamp, pauses) => {
    let duration = parseInt(endTimestamp) - parseInt(startTimestamp);

    for (let pause of pauses) {
        if (!pause.endTimestamp) {
            duration -= parseInt(endTimestamp) - parseInt(pause.startTimestamp);
            break;
        } else {
            duration -= parseInt(pause.endTimestamp) - parseInt(pause.startTimestamp);
        }
    }

    return duration;
};

export const msToMinutes = (ms) => {
    return Math.floor(ms / 1000) / 60;
};

export const isFixedType = (sessionType) => {
    return sessionType.toUpperCase() === FIXED_SESSION_TYPE;
};

const calculatePriceForRentalTypes = (minuteDuration, rentalTypes = []) => {
    return (
        (minuteDuration / 60) *
        rentalTypes.reduce(
            (curSum, type) => curSum + parseFloat(type.hourlyRateIncrease.$numberDecimal),
            0.0
        )
    );
};

const calculatePriceForCustomRates = (minuteDuration, hourlyRate, customRates = []) => {
    if (!hourlyRate.$numberDecimal) {
        hourlyRate = { $numberDecimal: parseFloat(hourlyRate) };
    }

    let totalPrice = 0.0;

    [...customRates, { timeUnit: 60, rate: { ...hourlyRate } }]
        .sort((a, b) => {
            return b.timeUnit - a.timeUnit;
        })
        .forEach((customRate) => {
            totalPrice +=
                Math.floor(minuteDuration / customRate.timeUnit) *
                parseFloat(customRate.rate.$numberDecimal);

            minuteDuration %= customRate.timeUnit;
        });

    if (minuteDuration > 0) {
        totalPrice += (parseFloat(hourlyRate.$numberDecimal) * minuteDuration) / 60;
    }

    return totalPrice;
};
