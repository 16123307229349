import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHttpClient } from '../../provider/HttpClientProvider';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import './RentalSessionHistoryTable.css';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { RENTAL_SESSION_QUERY_KEY } from '../utils/queryConstants';
import { Box, IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import RentalSessionAdminEditPopup from '../../RentalSessions/RentalSessionPopUps/AdminEditPopUp/RentalSessionAdminEditPopUp';
import Alert from '../Alert/Alert';
import { useAuth } from '../../provider/AuthProvider';
import ExportDailyStatisticsExcel from '../ExportDailyStatisticsExcel/ExportDailyStatisticsExcel';
import { convertDateRangeToTimestamp } from '../utils/timestampUtils';

const emptyObj = {
    docs: [],
};

function RentalSessionHistoryTable() {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    const { isAdmin } = useAuth();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [dateRange, setDateRange] = useState({
        startDate: dayjs(Date.now()),
        endDate: dayjs(Date.now()),
    });
    const [editState, setEditState] = useState({ editing: false, curSession: null });
    const [error, setError] = useState();

    const {
        data = emptyObj,
        isError: isRentalSessionsError,
        isFetching: isRentalSessionsFetching,
        isLoading: isRentalSessionsLoading,
        isPreviousData,
    } = useGetRentalSessions(pagination.pageIndex, pagination.pageSize, fetchData, dateRange);
    const { mutateAsync: edit } = useUpdateRentalSession(setError);

    useEffect(() => {
        if (!isPreviousData && data?.hasNextPage) {
            queryClient.prefetchQuery({
                queryKey: [
                    RENTAL_SESSION_QUERY_KEY,
                    pagination.pageIndex + 1,
                    pagination.pageSize,
                    dateRange,
                ],
                queryFn: () =>
                    fetchData(httpClient, pagination.pageIndex + 1, pagination.pageSize, dateRange),
            });
        }
    }, [
        data,
        isPreviousData,
        dateRange,
        pagination.pageSize,
        pagination.pageIndex,
        queryClient,
        httpClient,
    ]);

    const handleCloseEditing = useCallback(() => {
        setEditState({ editing: false, curSession: null });
    }, [setEditState]);

    const handleDatePick = useCallback(
        (value, targetedDate) => {
            setDateRange((curDateRange) => ({ ...curDateRange, [targetedDate]: value }));
        },
        [setDateRange]
    );

    const columns = useMemo(
        () => [
            {
                header: 'Rental Name',
                accessorKey: 'rentalName',
            },
            {
                header: 'Category',
                accessorKey: 'rentalCategoryName',
            },
            {
                header: 'Session Type',
                accessorKey: 'priceType',
            },
            {
                header: 'Total Price',
                accessorKey: 'totalPrice.$numberDecimal',
            },
            {
                header: 'Cash Payment',
                accessorFn: (dataRow) => {
                    return dataRow.paymentCash ? dataRow.paymentCash.$numberDecimal : 0;
                },
            },
            {
                header: 'Credit Payment',
                accessorFn: (dataRow) => {
                    return dataRow.paymentCredit ? dataRow.paymentCredit.$numberDecimal : 0;
                },
            },
            {
                header: 'Date',
                accessorFn: (dataRow) => {
                    const startDate = new Date(parseInt(dataRow.startTimestamp));

                    if (dataRow.endTimestamp) {
                        const endDate = new Date(parseInt(dataRow.endTimestamp));
                        return (
                            startDate.toLocaleString('it-IT') +
                            ' - ' +
                            endDate.toLocaleTimeString('it-IT')
                        );
                    } else {
                        return startDate.toLocaleString('it-IT');
                    }
                },
            },
            {
                header: 'Status',
                accessorFn: (dataRow) => (dataRow.live ? 'LIVE' : 'ENDED'),
                maxSize: 100,
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,

        data: data.docs,

        enableColumnOrdering: false,

        enableDensityToggle: false,

        enableRowActions: true,

        enableColumnFilters: false,

        enableSorting: false,

        enableColumnFilterModes: false,

        enableHiding: false,

        enableColumnActions: false,

        enablePagination: true,

        manualPagination: true,

        enableToolbarInternalActions: false,

        rowCount: data.totalDocs,

        positionPagination: 'both',

        enableBottomToolbar: true,

        positionToolbarDropZone: 'none',

        positionActionsColumn: 'last',

        layoutMode: 'grid',

        getRowId: (row) => row._id,

        renderDetailPanel: ({ row }) => <HistoryDetailPanel session={row.original} />,

        muiTableHeadCellProps: {},

        muiTableBodyRowProps: {
            hover: false,
            sx: {
                // height: '6vh',
            },
        },

        muiTableContainerProps: {
            sx: {
                overflowY: 'scroll',
                height: '100vh',
            },
        },

        muiSearchTextFieldProps: {
            sx: {
                width: '10rem',
            },
        },

        muiTopToolbarProps: {
            sx: {
                '& > div:nth-of-type(2)': {
                    width: '100%',
                    flex: '1',
                },
                '&': {
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0.2rem 0',
                },
            },
        },

        muiPaginationProps: {
            sx: {
                margin: '0',
            },
        },

        onPaginationChange: setPagination,

        renderTopToolbarCustomActions: () => (
            <div className="topTolbarCustomActions-wrapper">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <DatePicker
                            name="startDate"
                            label="Start Date"
                            value={dateRange.startDate}
                            onChange={(newValue) => handleDatePick(newValue, 'startDate')}
                            className="datePicker"
                        />

                        <DatePicker
                            name="startDate"
                            label="End Date"
                            value={dateRange.endDate}
                            onChange={(newValue) => handleDatePick(newValue, 'endDate')}
                            className="datePicker"
                        />
                    </DemoContainer>
                </LocalizationProvider>
                <ExportDailyStatisticsExcel onError={setError} />
            </div>
        ),

        renderRowActions: ({ row }) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                <IconButton
                    disabled={!isAdmin}
                    onClick={() => {
                        setEditState({ editing: true, curSession: row.original });
                    }}>
                    <Edit />
                </IconButton>
            </Box>
        ),

        state: {
            isLoading: isRentalSessionsLoading,
            showAlertBanner: isRentalSessionsError,
            showProgressBars: isRentalSessionsFetching,
            pagination,
        },

        initialState: {
            expanded: false,
        },
    });

    return (
        <>
            <MaterialReactTable table={table} />
            {editState.editing ? (
                <RentalSessionAdminEditPopup
                    session={editState.curSession}
                    onClose={handleCloseEditing}
                    onEdit={edit}
                />
            ) : null}
            <Alert message={error} setMessage={setError} />
        </>
    );
}

export default RentalSessionHistoryTable;

function HistoryDetailPanel({ session }) {
    return (
        <div className="history-detail-wrapper">
            <div className="history-detail">
                <h4>Pricing</h4>
                <span>
                    <h5>Prepaid Cash:</h5>
                    {session.prePaidCash?.reduce(
                        (partialSum, prepaid) => partialSum + parseFloat(prepaid.$numberDecimal),
                        0
                    )}
                </span>
                <span>
                    <h5>Prepaid Credit:</h5>
                    {session.prePaidCredit?.reduce(
                        (partialSum, prepaid) => partialSum + parseFloat(prepaid.$numberDecimal),
                        0
                    )}
                </span>
                <span>
                    <h5>Hourly Rate:</h5>
                    <span>{session.hourlyRate?.$numberDecimal}</span>
                </span>
            </div>
            <div className="history-detail">
                <h4>Custom Rates:</h4>

                {session.customRates?.length ? (
                    session.customRates.map((rate) => (
                        <div key={rate._id} className="history-detail-item">
                            <h5>{rate.timeUnit}</h5>
                            <span>{rate.rate.$numberDecimal}</span>
                        </div>
                    ))
                ) : (
                    <i>No RentalTypes Used</i>
                )}
            </div>
            <div className="history-detail">
                <h4>Rental Types:</h4>

                {session.rentalTypes?.length ? (
                    session.rentalTypes.map((type) => <h5 key={type._id}>{type.name}</h5>)
                ) : (
                    <i>No RentalTypes Used</i>
                )}
            </div>
            <div className="history-detail">
                <h4>Used Products:</h4>
                {session.usedProducts?.length ? (
                    session.usedProducts.map((product) => (
                        <div key={product._id} className="history-detail-item">
                            <h5>{product.name}</h5>
                            <span>{product.quantity}</span>
                        </div>
                    ))
                ) : (
                    <i>No Products Used</i>
                )}
            </div>
        </div>
    );
}

const fetchData = async (httpClient, pageIndex, pageSize, dateRange) => {
    return await httpClient
        .get(
            `/rentalSession/history?page=` +
                (pageIndex + 1) +
                '&pageSize=' +
                pageSize +
                (dateRange.startDate
                    ? '&startDate=' + convertDateRangeToTimestamp(dateRange.startDate.$d)
                    : '') +
                (dateRange.endDate
                    ? '&endDate=' + convertDateRangeToTimestamp(dateRange.endDate.$d)
                    : '')
        )
        .then((result) => {
            if (result.status === 200) {
                return result.data;
            }
        });
};

function useGetRentalSessions(pageIndex, pageSize, fetchData, dateRange) {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [RENTAL_SESSION_QUERY_KEY, pageIndex, pageSize, dateRange],
        queryFn: () => fetchData(httpClient, pageIndex, pageSize, dateRange),
        keepPreviousData: true,
        staleTime: 5000,
    });
}

function useUpdateRentalSession(setError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (editedRentalSessionInfo) => {
            return await httpClient
                .put('/rentalSession/edit', editedRentalSessionInfo)
                .then(() => {
                    return true;
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_QUERY_KEY] }),
    });
}
