import { Navigate } from 'react-router-dom';
import { useAuth } from '../provider/AuthProvider';
import './ProtectedRoute.css';
import ConfigurationWrapper from '../shared/ConfigurationWrapper/ConfigurationWrapper';

export const ConfigurationProtectedRoute = () => {
    const { isAdmin } = useAuth();

    if (!isAdmin) {
        return <Navigate to="/" />;
    }

    return <ConfigurationWrapper />;
};
