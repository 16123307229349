import { useState } from 'react';
import { AddCircleOutline } from '@mui/icons-material';
import RentalSessionSetupPupUp from '../../../RentalSessionPopUps/SetupPopUp/RentalSessionSetupPopUp';

function InactiveRentalSessionCard({ rental, rentalCategory, onError }) {
    const [sessionSetup, setSessionSetup] = useState(false);
    return (
        <>
            <div className="rentalCard-left">
                <span className="rentalCard-name">{rental.name}</span>
                <div className="rentalCard-openPopup" onClick={() => setSessionSetup(true)}>
                    <AddCircleOutline className="rentalCard-openPupupPlus" fontSize="large" />
                </div>
            </div>

            {sessionSetup ? (
                <RentalSessionSetupPupUp
                    rentalTypes={rentalCategory.rentalTypes}
                    hourlyRate={rentalCategory.hourlyRate}
                    customRates={rentalCategory.customRates}
                    setSessionSetup={setSessionSetup}
                    rentalId={rental._id}
                    setError={onError}
                />
            ) : null}
        </>
    );
}

export default InactiveRentalSessionCard;
