import { useMemo, useState } from 'react';
import { useHttpClient } from '../provider/HttpClientProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '../shared/Alert/Alert';
import ProductBulkStockControlTable from './ProductBulkStockControlTable';
import { PRODUCT_QUERY_KEY } from '../shared/utils/queryConstants';

// TODO Add error handling to every request
function ProductStockControlTable() {
    const [isBulkEdit, setIsBulkEdit] = useState(false);

    const [validationErrors, setValidationErrors] = useState({});
    const [error, setError] = useState();

    //call READ hook
    const { data: products = [], isError, isFetching, isLoading } = useGetProducts(setError);
    //call UPDATE hook
    const { mutateAsync: updateProductQuantity, isPending: isUpdatingProductQuantity } =
        useUpdateProduct(setError);

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                enableEditing: false,
            },
            {
                header: 'Price',
                id: 'price',
                enableEditing: false,
                accessorFn: (dataRow) => parseFloat(dataRow?.price?.$numberDecimal),
            },
            {
                header: 'Quantity',
                accessorKey: 'quantity',
                muiEditTextFieldProps: {
                    type: 'number',
                    required: true,
                    error: !!validationErrors?.quantity,
                    helperText: validationErrors?.quantity,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            quantity: undefined,
                        }),
                },
            },
        ],
        [validationErrors]
    );

    //UPDATE action
    const handleSave = async ({ values, row, table }) => {
        const newValidationErrors = validateProduct(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }

        setValidationErrors({});
        values._id = row.original._id;
        if (await updateProductQuantity(values)) {
            table.setEditingRow(null);
        }
    };

    const table = useMaterialReactTable({
        columns,

        data: products,

        enableColumnOrdering: true,

        enableDensityToggle: false,

        enablePagination: false,

        enableEditing: true,

        enableRowActions: true,

        enableBottomToolbar: false,

        positionActionsColumn: 'last',

        editDisplayMode: 'row',

        layoutMode: 'grid',

        getRowId: (row) => row._id,

        onEditingRowCancel: () => setValidationErrors({}),

        onEditingRowSave: handleSave,

        muiToolbarAlertBannerProps: isLoading
            ? {
                  color: 'error',
                  children: 'Error loading data',
              }
            : undefined,

        muiTableContainerProps: {
            sx: {
                overflowY: 'scroll',
                flex: 1,
            },
        },

        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={() => {
                    setIsBulkEdit(true);
                }}>
                Bulk Update Stock
            </Button>
        ),

        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),

        state: {
            isLoading: isLoading,
            isSaving: isUpdatingProductQuantity,
            showAlertBanner: isError,
            showProgressBars: isFetching,
        },
    });

    return (
        <>
            <MaterialReactTable table={table} />

            {isBulkEdit ? (
                <ProductBulkStockControlTable products={products} setOpen={setIsBulkEdit} />
            ) : null}

            <Alert message={error} setMessage={setError} />
        </>
    );
}

//READ hook (get products from api)
function useGetProducts() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [PRODUCT_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get('/product').then((result) => {
                if (result.status === 200) {
                    return result.data.reverse();
                }
            });
        },
    });
}

//UPDATE hook (put product in api)
function useUpdateProduct(setError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (product) => {
            return await httpClient
                .put('/product/quantity', product)
                .then((result) => {
                    return true;
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        //client side optimistic update
        onMutate: (newProductInfo) => {
            queryClient.setQueryData([PRODUCT_QUERY_KEY], (prevProducts) =>
                prevProducts?.map((prevProduct) =>
                    prevProduct._id === newProductInfo._id
                        ? {
                              ...newProductInfo,
                              price: { $numberDecimal: newProductInfo.price },
                          }
                        : prevProduct
                )
            );
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [PRODUCT_QUERY_KEY] }),
    });
}

const validateQuantity = (value) => isNumeric(value) && parseInt(value) >= 0;

function isNumeric(value) {
    return /^-?\d+$/.test(value);
}

function validateProduct(product) {
    return {
        quantity: !validateQuantity(product.quantity)
            ? 'Quantity must be a whole number, more than or equal to 0'
            : '',
    };
}

export default ProductStockControlTable;
