import './App.css';
import AuthProvider from './components/provider/AuthProvider';
import { HttpClientProvider } from './components/provider/HttpClientProvider';
import Routes from './components/routes/Routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
          refetchInterval: 10 * 1000,
          refetchOnWindowFocus: false,
        },
      },
});

function App() {
    return (
        <div className="App">
            <AuthProvider>
                <HttpClientProvider>
                    <QueryClientProvider client={queryClient}>
                        <Routes />
                    </QueryClientProvider>
                </HttpClientProvider>
            </AuthProvider>
        </div>
    );
}

export default App;
