import { useAuth } from '../../provider/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import {
    AppBar,
    IconButton,
    Menu,
    Toolbar,
    Typography,
    MenuItem,
    Button,
    Box,
    Container,
} from '@mui/material';
import { Logout, Menu as MenuIcon } from '@mui/icons-material';
import './Navbar.css';

const ADMIN_PAGES = ['stock', 'history', 'dashboard', 'configuration'];
const AGENT_PAGES = ['stock', 'history'];

function Navbar() {
    const navigate = useNavigate();

    const { setAuthToken, userInfo, isAdmin } = useAuth();
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleLogout = useCallback(() => {
        setAuthToken();
        navigate('/', { replace: true });
    }, [navigate, setAuthToken]);

    const handleOpenNavMenu = useCallback(
        (event) => {
            setAnchorElNav(event.currentTarget);
        },
        [setAnchorElNav]
    );

    const handleMenuItemClick = useCallback(
        (pageName) => {
            setAnchorElNav(null);
            navigate(`/${pageName}`, { replace: true });
        },
        [navigate, setAnchorElNav]
    );

    const handleNavItemClick = useCallback(
        (pageName) => {
            navigate(`/${pageName}`, { replace: true });
        },
        [navigate]
    );

    return (
        <AppBar position="static">
            <Container sx={{ margin: 0, width: '100%', maxWidth: { md: 'none' } }}>
                <Toolbar
                    sx={{ height: '3rem', minHeight: { xs: 'unset', md: 'unset' } }}
                    disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        onClick={() => handleMenuItemClick('sessions')}
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            cursor: 'pointer',
                            fontSize: '1rem',
                        }}>
                        MIMDINARE
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit">
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={() => setAnchorElNav(null)}
                            open={Boolean(anchorElNav)}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}>
                            {(isAdmin ? ADMIN_PAGES : AGENT_PAGES).map((page) => (
                                <MenuItem key={page} onClick={() => handleMenuItemClick(page)}>
                                    <Typography textAlign="center">{page.toUpperCase()}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/sessions"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}>
                        MIMDINARE
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, gap: '2rem' }}>
                        {(isAdmin ? ADMIN_PAGES : AGENT_PAGES).map((page) => (
                            <Button
                                key={page}
                                onClick={() => handleNavItemClick(page)}
                                sx={{
                                    my: 2,
                                    color: 'white',
                                    display: 'block',
                                    margin: 0,
                                    fontSize: '0.8rem',
                                }}>
                                {page}
                            </Button>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 0,
                            flexDirection: 'row',
                            display: 'flex',
                            gap: '1rem',
                        }}>
                        <Typography sx={{ fontFamily: 'Roboto,Helvetica,Arial,sans-serif' }}>
                            {userInfo.username}
                        </Typography>
                        <IconButton onClick={handleLogout} sx={{ p: 0 }}>
                            <Logout style={{ color: 'white' }} />
                        </IconButton>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navbar;
