import { useEffect, useState } from 'react';
import { useStopwatch } from 'react-timer-hook';
import './Timers.css';
import { calculateOffset, calculatePrice, formatTime } from '../rentalSessionUtils';
import { useOrgOptions } from '../../provider/OrganizationOptionsProvider';
import { useRentalCategories } from '../../provider/RentalSessionsWrapperProvider';

function MimdinareTimer({
    startTimestamp,
    rentalTypes,
    setTotalPrice,
    totalPrice,
    isPaused,
    transferHistory,
    categoryId,
}) {
    const [offsetTimestamp, setOffsetTimestamp] = useState(calculateOffset(startTimestamp));
    const { totalSeconds, seconds, minutes, hours, isRunning, reset } = useStopwatch({
        autoStart: true,
        offsetTimestamp,
    });
    const { currency } = useOrgOptions();
    const { categoriesMap } = useRentalCategories();

    useEffect(() => {
        setOffsetTimestamp(calculateOffset(startTimestamp));
        reset(calculateOffset(startTimestamp), !isPaused);
    }, [startTimestamp, isPaused, reset]);

    useEffect(() => {
        setTotalPrice(
            calculatePrice(
                totalSeconds / 60,
                categoryId,
                rentalTypes,
                transferHistory,
                categoriesMap
            )
        );
    }, [totalSeconds, rentalTypes, setTotalPrice, categoryId, transferHistory, categoriesMap]);

    return (
        <>
            <div>
                <div
                    style={{ fontSize: '42px' }}
                    className={'mimdinare ' + (isRunning ? 'live' : 'offline')}>
                    <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:
                    <span>{formatTime(seconds)}</span>
                </div>
                <div style={{ height: 'fitContent' }}>
                    <label className="liveSession-price-label" htmlFor="price">
                        Price:
                    </label>
                    <input
                        id="price"
                        className="liveSession-price"
                        value={totalPrice + currency.sign}
                        disabled
                    />
                </div>
            </div>
        </>
    );
}

export default MimdinareTimer;
