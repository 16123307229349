import { useMemo, useState } from 'react';
import { useHttpClient } from '../provider/HttpClientProvider';
import './RentalCategoryTable.css';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import TableWrapper from '../shared/TableWrapper/TableWrapper';
import RentalTypeInput from './RentalTypeInput';
import CustomRatesInput from './CustomRatesInput';
import { RENTAL_CATEGORY_QUERY_KEY } from '../shared/utils/queryConstants';

const emptyObject = { name: '', hourlyRate: { $numberDecimal: 0 }, rentalTypes: [] };

// TODO Add error handling to every request
function RentalCategoryTable() {
    const [validationErrors, setValidationErrors] = useState({});

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.name,
                    helperText: validationErrors?.name,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            name: undefined,
                        }),
                },
            },
            {
                header: 'Hourly Rate',
                id: 'hourlyRate',
                accessorFn: (dataRow) => parseFloat(dataRow?.hourlyRate?.$numberDecimal),
                muiEditTextFieldProps: {
                    type: 'number',
                    required: true,
                    error: !!validationErrors?.hourlyRate,
                    helperText: validationErrors?.hourlyRate,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            hourlyRate: undefined,
                        }),
                },
            },
            {
                header: 'Custom Rates',
                id: 'displayCustomRates',
                accessorFn: (dataRow) => {
                    if (dataRow.customRates instanceof Array) {
                        return dataRow.customRates
                            ?.sort((a, b) => a.timeUnit - b.timeUnit)
                            .map((rate) => rate.timeUnit + ' : ' + rate.rate.$numberDecimal)
                            .join(', ');
                    }
                    return dataRow.customRates;
                },
                muiEditTextFieldProps: {
                    type: 'customRates',
                    required: true,
                    error: !!validationErrors?.customRates,
                    helperText: validationErrors?.customRates,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            customRates: undefined,
                        }),
                },
                Edit: ({ cell, column, row, table }) => {
                    return (
                        <CustomRatesInput
                            onChange={(customRates) => {
                                row._valuesCache.customRates = customRates;
                            }}
                            customRates={row.original.customRates}
                        />
                    );
                },
            },
            {
                header: 'Types',
                id: 'displayRentalTypes',
                accessorFn: (dataRow) => {
                    if (dataRow.rentalTypes instanceof Array) {
                        return dataRow.rentalTypes?.map((type) => type.name).join(', ');
                    }
                    return dataRow.rentalTypes;
                },
                muiEditTextFieldProps: {
                    type: 'rentalTypes',
                    required: true,
                    error: !!validationErrors?.rentalTypes,
                    helperText: validationErrors?.rentalTypes,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            rentalTypes: undefined,
                        }),
                },
                Edit: ({ cell, column, row, table }) => {
                    return (
                        <RentalTypeInput
                            onChange={(rentalTypes) => {
                                row._valuesCache.rentalTypes = rentalTypes;
                            }}
                            rentalTypes={row.original.rentalTypes}
                        />
                    );
                },
            },
        ],
        [validationErrors]
    );

    return (
        <TableWrapper
            columns={columns}
            useCreate={useCreateRentalCategory}
            useUpdate={useUpdateRentalCategory}
            useGet={useGetRentalCategories}
            useDelete={useDeleteRentalCategory}
            validate={validateProduct}
            emptyObject={emptyObject}
            setValidationErrors={setValidationErrors}
            name={'Rental Category'}
        />
    );
}

//CREATE hook (post new rental category to api)
function useCreateRentalCategory(setError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (rentalCategory) => {
            return await httpClient
                .post('/rentalCategory', rentalCategory)
                .then((result) => {
                    if (result.status === 200) {
                        return true;
                    }
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        //client side optimistic update
        onMutate: (newRentalCategoryInfo) => {
            queryClient.setQueryData([RENTAL_CATEGORY_QUERY_KEY], (prevRentalCategories) => [
                {
                    ...newRentalCategoryInfo,
                    hourlyRate: { $numberDecimal: newRentalCategoryInfo.hourlyRate },
                    _id: (Math.random() + 1).toString(36).substring(7),
                },
                ...prevRentalCategories,
            ]);
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [RENTAL_CATEGORY_QUERY_KEY] }),
    });
}

//READ hook (get rental categories from api)
function useGetRentalCategories() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [RENTAL_CATEGORY_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get('/rentalCategory').then((result) => {
                if (result.status === 200) {
                    return result.data.reverse();
                }
            });
        },
    });
}

//UPDATE hook (put rental category in api)
function useUpdateRentalCategory(setError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (rentalCategory) => {
            return await httpClient
                .put('/rentalCategory', rentalCategory)
                .then((result) => {
                    return true;
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        //client side optimistic update
        onMutate: (newRentalCategoryInfo) => {
            queryClient.setQueryData([RENTAL_CATEGORY_QUERY_KEY], (prevRentalCategories) =>
                prevRentalCategories?.map((prevRentalCategory) =>
                    prevRentalCategory._id === newRentalCategoryInfo._id
                        ? {
                              ...newRentalCategoryInfo,
                              hourlyRate: { $numberDecimal: newRentalCategoryInfo.hourlyRate },
                          }
                        : prevRentalCategory
                )
            );
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [RENTAL_CATEGORY_QUERY_KEY] }),
    });
}

//DELETE hook (delete rental category in api)
function useDeleteRentalCategory() {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (rentalCategoryId) => {
            return await httpClient
                .delete('/rentalCategory', { data: { _id: rentalCategoryId } })
                .then((result) => {
                    return result;
                });
        },
        //client side optimistic update
        onMutate: (rentalCategoryId) => {
            queryClient.setQueryData([RENTAL_CATEGORY_QUERY_KEY], (prevRentalCategories) =>
                prevRentalCategories?.filter(
                    (rentalCategory) => rentalCategory._id !== rentalCategoryId
                )
            );
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [RENTAL_CATEGORY_QUERY_KEY] }),
    });
}

const validateRequired = (value) => !!value.length;
const validateDecimal = (value) => !!value && parseFloat(value) > 0;

function validateProduct(rentalCategory) {
    return {
        name: !validateRequired(rentalCategory.name) ? 'Name is Required' : '',
        hourlyRate: !validateDecimal(rentalCategory.hourlyRate)
            ? 'Hourly rate needs to be more than 0'
            : '',
    };
}

export default RentalCategoryTable;
