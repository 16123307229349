import { Navigate } from 'react-router-dom';
import { useAuth } from '../provider/AuthProvider';
import PieChartWidget from './PieChartWidget/PieChartWidget';
import BarChartWidget from './BarChartWidget/BarChartWidget';
import './Dashboard.css';
import LatencyBarChartWidget from './LatencyBarChartWidget/LatencyBarChartWidget';

function Dashboard() {
    const { isAdmin } = useAuth();

    if (!isAdmin) {
        return <Navigate to="/" />;
    }

    return (
        <div className="dashboard-wrapper-background">
            <div className="dashboard-wrapper">
                <PieChartWidget />
                <BarChartWidget />
            </div>
            <div className={'dashboard-bottom-wrapper'}>
                <LatencyBarChartWidget />
            </div>
        </div>
    );
}

export default Dashboard;
