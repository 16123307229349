import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHttpClient } from '../provider/HttpClientProvider';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import './ProductSales.css';
import ProductSelectionTable from './ProductSelectionTable';
import { Backdrop, CircularProgress } from '@mui/material';
import { Add, Cancel, Remove, ShoppingBasket } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Alert from '../shared/Alert/Alert';
import { PRODUCT_QUERY_KEY, PRODUCT_SALE_BALANCE_QUERY_KEY } from '../shared/utils/queryConstants';
import { useOrgOptions } from '../provider/OrganizationOptionsProvider';

const emptyResult = [];

function ProductSales({ sessionSale, setIsOpen, setIsLoding, isLoading }) {
    const queryClient = useQueryClient();
    const { data: products = emptyResult } = useGetProducts();
    const [displayProducts, setDisplayProducts] = useState(products);
    const [displaySelectedProducts, setDisplaySelectedProducts] = useState([]);
    const [totalPrice, setTotalPrice] = useState('0.00');
    const [paymentCredit, setPaymentCredit] = useState(0);
    const [paymentCash, setPaymentCash] = useState(0);
    const [isSubmited, setIsSubmited] = useState(false);
    const [isProductSalesTableOpen, setIsProductSalesTableOpen] = useState(
        sessionSale ? true : false
    );
    const { currency } = useOrgOptions();

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    useEffect(() => {
        setIsSubmitLoading(!!isLoading);
    }, [isLoading]);

    const [error, setError] = useState();

    const httpclient = useHttpClient();

    useEffect(() => {
        if (paymentCredit === 0) {
            setPaymentCash(totalPrice);
            setPaymentCredit(0);
        } else if (paymentCash === 0) {
            setPaymentCash(0);
            setPaymentCredit(totalPrice);
        } else {
            setPaymentCash(totalPrice);
            setPaymentCredit(0);
        }
    }, [totalPrice]); // eslint-disable-line

    useEffect(() => {
        setTotalPrice(
            displaySelectedProducts
                .reduce(
                    (partialSum, selectedProduct) =>
                        parseFloat(partialSum) +
                        selectedProduct.quantity * parseFloat(selectedProduct.price.$numberDecimal),
                    0
                )
                .toFixed(2)
        );
    }, [displaySelectedProducts]);

    useEffect(() => {
        setDisplayProducts(
            products.map((product) => {
                for (let selectedProduct of displaySelectedProducts) {
                    if (selectedProduct._id === product._id) {
                        return { ...selectedProduct };
                    }
                }

                return { ...product, quantity: 0 };
            })
        );
    }, [products, displaySelectedProducts]);

    const toggleProductSalesTable = useCallback(
        (isOpen) => {
            if (sessionSale) {
                setIsOpen(isOpen);
            } else {
                setIsProductSalesTableOpen(isOpen);
            }
        },
        [setIsProductSalesTableOpen, sessionSale, setIsOpen]
    );

    const increaseProductQuantity = useCallback(
        (id) => {
            setDisplaySelectedProducts((selectedProducts) => {
                let hasChanged = false;
                const newSelectedProducts = selectedProducts.map((selectedProduct) => {
                    if (id === selectedProduct._id) {
                        hasChanged = true;
                        return { ...selectedProduct, quantity: selectedProduct.quantity + 1 };
                    }

                    return { ...selectedProduct };
                });

                if (!hasChanged) {
                    for (let product of products) {
                        if (id === product._id) {
                            newSelectedProducts.push({ ...product, quantity: 1 });
                            break;
                        }
                    }
                }

                return newSelectedProducts;
            });
        },
        [products]
    );

    const decreaseProductQuantity = useCallback((id) => {
        setDisplaySelectedProducts((selectedProducts) => {
            const newSelectedProducts = selectedProducts.map((selectedProduct) => {
                if (id === selectedProduct._id) {
                    return { ...selectedProduct, quantity: selectedProduct.quantity - 1 };
                }

                return { ...selectedProduct };
            });

            return newSelectedProducts.filter((selectedProduct) => selectedProduct.quantity !== 0);
        });
    }, []);

    const handleChange = useCallback(
        (e) => {
            const changedField = e.target.name;
            let newValue = parseFloat(e.target.value);

            if (newValue > totalPrice) {
                newValue = totalPrice;
            }

            if (!newValue || newValue < 0) {
                newValue = 0;
            }

            if (changedField === 'cash') {
                setPaymentCash(newValue);
                setPaymentCredit((totalPrice - newValue).toFixed(2));
            } else {
                setPaymentCredit(newValue);
                setPaymentCash((totalPrice - newValue).toFixed(2));
            }
        },
        [totalPrice]
    );

    const handleReset = useCallback(() => {
        setDisplaySelectedProducts([]);
    }, [setDisplaySelectedProducts]);

    const handleSubmit = useCallback(async () => {
        if (!displaySelectedProducts.length) {
            return;
        }

        if (sessionSale) {
            setIsLoding(true);
            if (await sessionSale(displaySelectedProducts)) {
                handleReset();
                setIsSubmited(false);
                toggleProductSalesTable(false);
            }
            setIsLoding(false);
        } else {
            setIsSubmitLoading(true);

            // TODO change to query client call
            httpclient
                .post('/productSales', {
                    products: displaySelectedProducts,
                    totalPrice,
                    timestamp: Date.now(),
                    paymentCash,
                    paymentCredit,
                })
                .then((result) => {
                    if (result.status === 200) {
                        queryClient.invalidateQueries({
                            queryKey: [PRODUCT_SALE_BALANCE_QUERY_KEY],
                        });
                        handleReset();
                        setIsSubmited(false);
                    }
                    setIsSubmitLoading(false);
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                });
        }
    }, [
        displaySelectedProducts,
        totalPrice,
        paymentCash,
        paymentCredit,
        httpclient,
        handleReset,
        sessionSale,
        toggleProductSalesTable,
        setIsLoding,
        queryClient,
    ]);

    const handleDone = useCallback(() => {
        if (!displaySelectedProducts.length) {
            return;
        }

        setIsSubmited(true);
    }, [setIsSubmited, displaySelectedProducts]);

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                Header: ({ column }) => (
                    <div style={{ textAlign: 'left' }}>{column.columnDef.header}</div>
                ),
            },
            {
                header: 'Price',
                id: 'price',
                accessorFn: (dataRow) =>
                    parseFloat(dataRow.price.$numberDecimal).toFixed(2) + currency.sign,
                Cell: ({ cell }) => <div style={{ margin: 'auto' }}>{cell.getValue()}</div>,
            },
            {
                header: 'Quantity',
                accessorKey: 'quantity',
                Cell: ({ cell, row }) => (
                    <div style={{ margin: 'auto' }}>
                        <IconButton color="error" onClick={() => decreaseProductQuantity(row.id)}>
                            <Remove />
                        </IconButton>
                        <span>{cell.getValue()}</span>
                        <IconButton color="success" onClick={() => increaseProductQuantity(row.id)}>
                            <Add />
                        </IconButton>
                    </div>
                ),
            },
        ],
        [increaseProductQuantity, decreaseProductQuantity, currency.sign]
    );

    const selectedColumns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
            },
            {
                header: 'Quantity',
                accessorKey: 'quantity',
                Cell: ({ cell, row }) => (
                    <div style={{ margin: 'auto' }}>
                        <IconButton color="error" onClick={() => decreaseProductQuantity(row.id)}>
                            <Remove />
                        </IconButton>
                        <span>{cell.getValue()}</span>
                        <IconButton color="success" onClick={() => increaseProductQuantity(row.id)}>
                            <Add />
                        </IconButton>
                    </div>
                ),
            },
            {
                header: 'Price',
                id: 'price',
                accessorFn: (dataRow) =>
                    (parseFloat(dataRow.price.$numberDecimal) * dataRow.quantity).toFixed(2) +
                    currency.sign,
                Cell: ({ cell }) => <div style={{ margin: 'auto' }}>{cell.getValue()}</div>,
            },
        ],
        [increaseProductQuantity, decreaseProductQuantity, currency.sign]
    );

    return (
        <>
            {isProductSalesTableOpen ? (
                <>
                    <div className="productSales-wrapper">
                        <IconButton
                            style={{ color: 'var(--primary-red)' }}
                            className="closeButton"
                            onClick={() => toggleProductSalesTable(false)}>
                            <Cancel style={{ fontSize: '1.5rem' }} />
                        </IconButton>
                        <ProductSelectionTable
                            columns={columns}
                            products={displayProducts}
                            height={'15rem'}
                            showCloseButton={true}
                            className={'productSelectionTable'}
                        />
                        <div style={{ height: '0.5rem' }}></div>
                        <div
                            style={{
                                backgroundColor: 'var(--primary-color)',
                                color: 'white',
                                borderRadius: '4px',
                            }}>
                            <div
                                style={{
                                    padding: '3px',
                                    fontWeight: '600',
                                    letterSpacing: '1px',
                                    fontSize: '0.7rem',
                                }}>
                                Selected Products
                            </div>
                            <ProductSelectionTable
                                enableToolBar={false}
                                enableHeader={false}
                                columns={selectedColumns}
                                products={displaySelectedProducts}
                                height={'5rem'}
                            />
                            <div
                                className="productSale-recipe"
                                style={{ display: 'flex', flexDirection: 'column' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '0px 20px 0 ',
                                        marginTop: '10px',
                                    }}>
                                    <span style={{ fontWeight: 600, fontSize: '0.8rem' }}>
                                        Total Price
                                    </span>
                                    <input
                                        disabled
                                        value={totalPrice + currency.sign}
                                        style={{
                                            width: '3.5rem',
                                            fontSize: '0.8rem',
                                            textAlign: 'right',
                                            marginRight: '30px',
                                            fontWeight: 600,
                                        }}
                                    />
                                </div>
                                {sessionSale ? null : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            justifyContent: 'center',
                                            gap: '1rem',
                                            marginTop: '10px',
                                        }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '3.5rem',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                            <label
                                                htmlFor="cash"
                                                style={{ fontWeight: 600, fontSize: '0.8rem' }}>
                                                CASH
                                            </label>
                                            <input
                                                type="number"
                                                id="cash"
                                                name="cash"
                                                value={Number(paymentCash).toString()}
                                                onChange={handleChange}
                                                min="0"
                                                max={totalPrice}
                                                className="payment-input"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '3.5rem',
                                            }}>
                                            <label
                                                htmlFor="credit"
                                                style={{ fontWeight: 600, fontSize: '0.8rem' }}>
                                                CREDIT
                                            </label>
                                            <input
                                                type="number"
                                                id="credit"
                                                name="credit"
                                                value={Number(paymentCredit).toString()}
                                                onChange={handleChange}
                                                min="0"
                                                max={totalPrice}
                                                className="payment-input"
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="selectedProducts-buttons">
                                    <button className="reset-button" onClick={handleReset}>
                                        Reset
                                    </button>
                                    <button className="done-button" onClick={handleDone}>
                                        Done
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="productTable-toggle">
                    <IconButton onClick={() => toggleProductSalesTable(true)}>
                        <ShoppingBasket style={{ fontSize: '1.8rem' }} />
                    </IconButton>
                </div>
            )}
            <Alert message={error} setMessage={setError} />

            <Recipe
                isSubmited={isSubmited}
                displaySelectedProducts={displaySelectedProducts}
                totalPrice={totalPrice}
                paymentCash={paymentCash}
                paymentCredit={paymentCredit}
                setIsSubmited={setIsSubmited}
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
                isSessionSale={!!sessionSale}
            />
        </>
    );
}

export default ProductSales;

function useGetProducts() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [PRODUCT_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get('/product').then((result) => {
                if (result.status === 200) {
                    return result.data.reverse();
                }
            });
        },

        refetchInterval: 5 * 60 * 1000,
    });
}

function Recipe({
    isSubmited,
    displaySelectedProducts,
    totalPrice,
    paymentCash,
    paymentCredit,
    setIsSubmited,
    handleSubmit,
    isLoading,
    isSessionSale,
}) {
    const { currency } = useOrgOptions();

    const handleClose = useCallback(() => {
        if (!isLoading) {
            setIsSubmited(false);
        }
    }, [setIsSubmited, isLoading]);

    const selectedColumns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
            },
            {
                header: 'Quantity',
                accessorKey: 'quantity',
                Cell: ({ cell }) => <div style={{ margin: 'auto' }}>{cell.getValue()}</div>,
            },
            {
                header: 'Price',
                id: 'price',
                accessorFn: (dataRow) =>
                    (parseFloat(dataRow.price.$numberDecimal) * dataRow.quantity).toFixed(2) +
                    currency.sign,
                Cell: ({ cell }) => <div style={{ margin: 'auto' }}>{cell.getValue()}</div>,
            },
        ],
        [currency.sign]
    );

    return isSubmited ? (
        <>
            <Backdrop className="recipe-backdrop" onClick={handleClose} open={true} />
            <div className="recipe-wrapper">
                <h1>Recipe</h1>
                <div className="recipe-selectedProducts">
                    <ProductSelectionTable
                        enableToolBar={false}
                        enableHeader={false}
                        columns={selectedColumns}
                        products={displaySelectedProducts}
                        height={'30vh'}
                    />
                </div>
                <div className="recipe-totalamount">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                        }}>
                        {!isSessionSale ? (
                            <>
                                <span>Cash:</span>
                                <span>Credit:</span>
                            </>
                        ) : null}
                        <span>Total:</span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        {!isSessionSale ? (
                            <>
                                <span>{parseFloat(paymentCash).toFixed(2) + currency.sign}</span>
                                <span>{parseFloat(paymentCredit).toFixed(2) + currency.sign}</span>
                            </>
                        ) : null}
                        <span>{parseFloat(totalPrice).toFixed(2) + currency.sign}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '30px' }}>
                    <button onClick={handleClose} disabled={isLoading}>
                        Close
                    </button>
                    <button onClick={handleSubmit} disabled={isLoading}>
                        Submit
                        {isLoading ? <CircularProgress size={'0.8rem'} color="inherit" /> : null}
                    </button>
                </div>
            </div>
        </>
    ) : null;
}
