import { useQuery } from '@tanstack/react-query';
import { useHttpClient } from '../../provider/HttpClientProvider';
import { convertDateRangeToTimestamp } from '../../shared/utils/timestampUtils';
import { USER_QUERY_KEY } from '../../shared/utils/queryConstants';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { BarChart, DEFAULT_Y_AXIS_KEY } from '@mui/x-charts';
import { CHART_COLORS } from '../dashboardConstans';
import './LatencyBarChartWidget.css';
import '../Dashboard.css';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

const emptyResult = [];
const LOADING_TEXT = 'LOADING';
const NO_STATS_TEXT = 'No Stats Available';
const LOADING_BARS = [{ [LOADING_TEXT]: 100, username: LOADING_TEXT }];
const NO_DATA_BARS = [{ [NO_STATS_TEXT]: 100, username: NO_STATS_TEXT }];
const LOADING_DATA_SET = { max: LOADING_BARS, avg: LOADING_BARS };
const NO_DATA_DATA_SET = { max: NO_DATA_BARS, avg: NO_DATA_BARS };
const NO_DATA_SERIES = {
    max: [{ dataKey: NO_STATS_TEXT, label: NO_STATS_TEXT }],
    avg: [{ dataKey: NO_STATS_TEXT, label: NO_STATS_TEXT }],
};
const LOADING_SERIES = {
    max: [{ dataKey: LOADING_TEXT, label: LOADING_TEXT }],
    avg: [{ dataKey: LOADING_TEXT, label: LOADING_TEXT }],
};

function LatencyBarChartWidget() {
    const [timestamp, setTimestamp] = useState({
        start: dayjs(new Date(new Date(Date.now() - 518400000).toDateString())),
        end: null,
    });
    const { data: latencies = emptyResult, isFetching: isLatenciesFetching } = useGetLatency(
        timestamp.start,
        timestamp.end
    );
    const { data: users = emptyResult, isFetching: isUsersFetching } = useGetUsers();

    const usersById = useMemo(() => {
        return users.reduce((usersObj, user) => ({ ...usersObj, [user._id]: user }), {});
    }, [users]);

    const chartDataSet = useMemo(() => {
        if (latencies.length) {
            let max = [];
            let avg = [];
            for (let userLatency of latencies) {
                userLatency.maxLatency = parseFloat(userLatency.maxLatency.toFixed(2));
                userLatency.averageLatency = parseFloat(userLatency.averageLatency.toFixed(2));

                const username = usersById[userLatency._id]?.username;

                if (username) {
                    max.push({
                        username,
                        maxLatency: userLatency.maxLatency,
                    });
                    avg.push({
                        username,
                        averageLatency: userLatency.averageLatency,
                    });
                }
            }

            max.sort((a, b) => a.maxLatency - b.maxLatency);

            avg.sort((a, b) => b.averageLatency - a.averageLatency);

            return {
                max: max,
                avg: avg,
            };
        }

        return isLatenciesFetching || isUsersFetching ? LOADING_DATA_SET : NO_DATA_DATA_SET;
    }, [latencies, isLatenciesFetching, isUsersFetching, usersById]);

    const chartSeries = useMemo(() => {
        const valueFormatter = (value) => `${value} Minutes`;
        if (latencies.length) {
            return {
                max: [{ dataKey: 'maxLatency', label: 'Maximum Latency', valueFormatter }],
                avg: [
                    {
                        dataKey: 'averageLatency',
                        label: 'Average Latency',
                        valueFormatter,
                    },
                ],
            };
        }
        return isLatenciesFetching || isUsersFetching ? LOADING_SERIES : NO_DATA_SERIES;
    }, [latencies, isLatenciesFetching, isUsersFetching]);

    const handleBarDatePick = useCallback(
        (value, targetedDate) => {
            setTimestamp((curDateRange) => ({ ...curDateRange, [targetedDate]: value }));
        },
        [setTimestamp]
    );

    return (
        <div className={'dashboard-latencyChart-wrapper'}>
            <h2 className="dashboard-chart-title">Income By Dates</h2>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                    <div className="dashboard-dataTimePickers-wrapper">
                        <DateTimePicker
                            name="startDate"
                            ampm={false}
                            label="Start Date"
                            value={timestamp.start}
                            onChange={(newValue) => handleBarDatePick(newValue, 'start')}
                            className="dashboard-dataTimePickers-item"
                            disableFuture={true}
                        />

                        <DateTimePicker
                            name="startDate"
                            ampm={false}
                            label="End Date"
                            value={timestamp.end}
                            onChange={(newValue) => handleBarDatePick(newValue, 'end')}
                            className="dashboard-dataTimePickers-item"
                            disableFuture={true}
                        />
                    </div>
                </DemoContainer>
            </LocalizationProvider>
            <div className={'dashboard-latencyCharts-container'}>
                <div className={'dashboard-latencyChart-max'}>
                    <BarChart
                        slotProps={{
                            axisTick: {
                                stroke: {
                                    fill: 'white',
                                },
                            },
                            legend: {
                                itemMarkWidth: 0,
                                itemMarkHeight: 0,
                                labelStyle: {
                                    fontSize: '1.2rem',
                                    fill: 'white',
                                },
                                direction: 'row',
                                position: { vertical: 'top', horizontal: 'middle' },
                            },
                        }}
                        colors={CHART_COLORS}
                        dataset={chartDataSet.max}
                        leftAxis={null}
                        rightAxis={DEFAULT_Y_AXIS_KEY}
                        xAxis={[{ scaleType: 'band', dataKey: 'username' }]}
                        series={chartSeries.max}
                        axisHighlight={{ x: 'line', y: 'line' }}
                    />
                </div>
                <div className={'dashboard-latencyChart-max'}>
                    <BarChart
                        p
                        slotProps={{
                            axisTick: {
                                stroke: {
                                    fill: 'white',
                                },
                            },
                            legend: {
                                itemMarkWidth: 0,
                                itemMarkHeight: 0,
                                labelStyle: {
                                    fontSize: '1.2rem',
                                    fill: 'white',
                                },
                                direction: 'row',
                                position: { vertical: 'top', horizontal: 'middle' },
                            },
                        }}
                        colors={CHART_COLORS.slice(1)}
                        dataset={chartDataSet.avg}
                        xAxis={[{ scaleType: 'band', dataKey: 'username' }]}
                        series={chartSeries.avg}
                        axisHighlight={{ x: 'line', y: 'line' }}
                    />
                </div>
            </div>
        </div>
    );
}

export default LatencyBarChartWidget;

function useGetLatency(start, end) {
    const httpClient = useHttpClient();

    start = convertDateRangeToTimestamp(start?.$d);
    end = convertDateRangeToTimestamp(end?.$d);

    return useQuery({
        queryKey: ['latency', start, end],
        queryFn: async () => {
            return await httpClient
                .get(`stats/latency/?startTimestamp=${start}&endTimestamp=${end}&shouldGroup=true`)
                .then((result) => {
                    if (result.status === 200) {
                        return result.data;
                    }
                });
        },
        refetchInterval: 60 * 1000,
    });
}

function useGetUsers() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [USER_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get(`/user`).then((result) => {
                if (result.status === 200) {
                    return result.data;
                }
            });
        },
        refetchInterval: 5 * 60 * 1000,
    });
}
