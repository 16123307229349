/* eslint-disable react/jsx-pascal-case */
import { useState } from 'react';
import './TableWrapper.css';
import {
    MRT_EditActionButtons,
    MaterialReactTable,
    createRow,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '../Alert/Alert';

// TODO Add error handling to every request
// TODO change handle save value processing to not show values on table before save, use different temp values
function TableWrapper({
    columns,
    useCreate,
    useUpdate,
    useGet,
    useDelete,
    validate,
    name,
    emptyObject,
    setValidationErrors,
    initialState = {},
}) {
    const [error, setError] = useState();

    //call CREATE hook
    const { mutateAsync: createObject, isPending: isCreating } = useCreate(setError);
    //call READ hook
    const { data: fetchedData = [], isError, isFetching, isLoading } = useGet(setError);
    //call UPDATE hook
    const { mutateAsync: updateObject, isPending: isUpdating } = useUpdate(setError);
    //call DELETE hook
    const { mutateAsync: deleteObject, isPending: isDeleting } = useDelete
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
          useDelete(setError)
        : {
              mutateAsync: (val) => console.error('Delete not defined, for value', val),
              isPending: false,
          };

    //CREATE action
    const handleCreate = async ({ values, table }) => {
        const newValidationErrors = validate(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }

        setValidationErrors({});
        if (await createObject(values)) {
            table.setCreatingRow(null);
        }
    };

    //UPDATE action
    const handleSave = async ({ values, row, table }) => {
        const newValidationErrors = validate(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }

        setValidationErrors({});
        values._id = row.original._id;
        if (await updateObject(values)) {
            table.setEditingRow(null);
        }
    };

    //DELETE action
    const openDeleteConfirmModal = (row) => {
        if (window.confirm('Are you sure you want to delete this?')) {
            deleteObject(row.original._id);
        }
    };

    const table = useMaterialReactTable({
        columns,

        data: fetchedData,

        enableColumnOrdering: true,

        enableDensityToggle: false,

        enablePagination: false,

        enableEditing: true,

        enableRowActions: true,

        enableBottomToolbar: false,

        positionActionsColumn: 'last',

        getRowId: (row) => row._id,

        muiToolbarAlertBannerProps: isLoading
            ? {
                  color: 'error',
                  children: 'Error loading data',
              }
            : undefined,

        muiTableContainerProps: {
            sx: {
                overflowY: 'scroll',
                flex: 1,
            },
        },

        onCreatingRowCancel: () => setValidationErrors({}),

        onCreatingRowSave: handleCreate,

        onEditingRowCancel: () => setValidationErrors({}),

        onEditingRowSave: handleSave,

        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h6">Create New {name}</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {internalEditComponents} {/* or render custom edit components here */}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),

        //optionally customize modal content
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h6">Edit {name}</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                    {internalEditComponents} {/* or render custom edit components here */}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),

        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                {useDelete ? (
                    <Tooltip title="Delete">
                        <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </Box>
        ),

        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={() => {
                    table.setCreatingRow(createRow(table, emptyObject));
                }}>
                Create New {name}
            </Button>
        ),

        state: {
            isLoading: isLoading,
            isSaving: isCreating || isUpdating || isDeleting,
            showAlertBanner: isError,
            showProgressBars: isFetching,
        },

        initialState,
    });

    return (
        <>
            <MaterialReactTable table={table} />
            <Alert message={error} setMessage={setError} />
        </>
    );
}

export default TableWrapper;
