import { useEffect, useState } from 'react';
import { NOTIFICATION_FLASHING_INTERVAL } from './TitleNotificationConstants';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function TitleNotification({ notification }) {
    const [isFlashing, setIsFlashing] = useState(false);

    useEffect(() => {
        if (notification) {
            const interval = setInterval(() => {
                setIsFlashing((curState) => !curState);
            }, NOTIFICATION_FLASHING_INTERVAL);
            return () => clearInterval(interval);
        } else {
            setIsFlashing(false);
        }
    }, [notification]);

    return (
        <HelmetProvider>
            <Helmet>
                <title>{isFlashing && notification ? notification : 'Mimdinare'}</title>
            </Helmet>
        </HelmetProvider>
    );
}

export default TitleNotification;
