import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHttpClient } from '../provider/HttpClientProvider';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import RentalSessionsCategoryCards from './RentalSessionCards/RentalSessionCategoryCards';
import ProductSales from '../ProductSales/ProductSales';
import Alert from '../shared/Alert/Alert';
import ExpiredAlert from './ExpiredAlert/ExpiredAlert';
import './RentalSessions.css';
import { Cached, CreditCard, LocalAtm } from '@mui/icons-material';
import CashRegistry from './CashRegistry/CashRegistry';
import {
    CASH_REGISTRY_BALANCE_QUERY_KEY,
    PRODUCT_SALE_BALANCE_QUERY_KEY,
    RENTAL_SESSION_QUERY_KEY,
    RENTAL_SESSION_SALE_BALANCE_QUERY_KEY,
} from '../shared/utils/queryConstants';
import TitleNotification from '../shared/TitleNotification/TitleNotification';
import { IconButton } from '@mui/material';
import { useOrgOptions } from '../provider/OrganizationOptionsProvider';
import {
    useRentalCategories,
    useRentals,
    useRentalSessions,
} from '../provider/RentalSessionsWrapperProvider';

const emptyResult = [];
const emptyObj = {};
const initialBalanceState = {
    sessionCash: 0,
    sessionCredit: 0,
    sessionTotal: 0,

    productCash: 0,
    productCredit: 0,
    productTotal: 0,

    cashRegistry: 0,

    totalCash: 0,
    totalCredit: 0,

    total: 0,
};

function RentalSessions() {
    const queryClient = useQueryClient();
    const cardRefs = useRef({});
    const [error, setError] = useState();
    const [expiredSessions, setExpiredSessions] = useState({});
    const { currency } = useOrgOptions();

    const {
        categories: rentalCategories = emptyResult,
        isRentalCategoriesError,
        isRentalCategoriesFetching,
        isRentalCategoriesLoading,
    } = useRentalCategories();
    const {
        rentals = emptyResult,
        isRentalsError,
        isRentalsFetching,
        isRentalsLoading,
    } = useRentals();
    const {
        rentalSessionsByCategory = emptyObj,
        isRentalSessionsError,
        isRentalSessionsFetching,
        isRentalSessionsLoading,
    } = useRentalSessions();

    const { data: sessionBalance = emptyObj, isFetching: isSessionBalanceFetching } =
        useGetDailySessionBalance();
    const { data: productSalesBalance = emptyObj, isFetching: isProductSalesBalancePending } =
        useGetDailyProductSaleBalance();
    const { data: cashRegistryBalance = emptyObj, isFetching: isCashRegistryBalancePending } =
        useGetDailyCashRegistryBalance();

    const rentalArraysByCategories = useMemo(
        () =>
            rentals.reduce(
                (rentalsObj, rental) => ({
                    ...rentalsObj,
                    [rental.categoryId]: [...(rentalsObj[rental.categoryId] ?? []), rental],
                }),
                {}
            ),
        [rentals]
    );

    const balance = useMemo(() => {
        const curBalance = { ...initialBalanceState };

        for (let categoryBalance in sessionBalance) {
            curBalance.sessionCash += parseFloat(
                sessionBalance[categoryBalance].cash.$numberDecimal
            );
            curBalance.sessionCredit += parseFloat(
                sessionBalance[categoryBalance].credit.$numberDecimal
            );
        }
        curBalance.sessionTotal = curBalance.sessionCash + curBalance.sessionCredit;

        for (let balance in productSalesBalance) {
            curBalance.productCash += parseFloat(productSalesBalance[balance].cash.$numberDecimal);
            curBalance.productCredit += parseFloat(
                productSalesBalance[balance].credit.$numberDecimal
            );
        }
        curBalance.productTotal = curBalance.productCash + curBalance.productCredit;

        curBalance.cashRegistry = cashRegistryBalance.sum ? cashRegistryBalance.sum : 0;

        curBalance.totalCash =
            curBalance.sessionCash + curBalance.productCash + curBalance.cashRegistry;
        curBalance.totalCredit = curBalance.sessionCredit + curBalance.productCredit;
        curBalance.total = curBalance.totalCash + curBalance.totalCredit;

        return curBalance;
    }, [cashRegistryBalance, productSalesBalance, sessionBalance]);

    useEffect(() => {
        const removeClosedSessionsFromExpiredList = () => {
            for (let sessionId in expiredSessions) {
                const expiredSession = expiredSessions[sessionId];
                const sessionsCategory = rentalSessionsByCategory[expiredSession.rentalCategoryId];

                if (!sessionsCategory || !sessionsCategory[expiredSession.rentalId]) {
                    updateExpiredSessions(expiredSession, 'REMOVE');
                }
            }
        };

        removeClosedSessionsFromExpiredList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rentalSessionsByCategory]);

    const updateExpiredSessions = useCallback(
        (session, updateMethod) => {
            switch (updateMethod) {
                case 'REMOVE':
                    setExpiredSessions((curState) => {
                        const newState = { ...curState };

                        delete newState[session.rentalId];

                        return newState;
                    });
                    break;

                case 'ADD':
                default:
                    setExpiredSessions((curState) => {
                        if (curState[session.rentalId]) {
                            return curState;
                        } else {
                            return { ...curState, [session.rentalId]: session };
                        }
                    });
                    break;
            }
        },
        [setExpiredSessions]
    );

    const handleAlertItemClick = useCallback(
        (s) => {
            cardRefs.current[s.rentalId].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        },
        [cardRefs]
    );

    const invalidateSessionAndBalanceQueries = useCallback(() => {
        queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_QUERY_KEY] });

        queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_SALE_BALANCE_QUERY_KEY] });
        queryClient.invalidateQueries({ queryKey: [PRODUCT_SALE_BALANCE_QUERY_KEY] });
        queryClient.invalidateQueries({ queryKey: [CASH_REGISTRY_BALANCE_QUERY_KEY] });
    }, [queryClient]);

    const columns = useMemo(
        () => [
            {
                header: 'Category',
                accessorKey: 'name',
            },
            {
                header: 'Cash',
                Cell: ({ row }) => {
                    return sessionBalance[row.id]
                        ? parseFloat(sessionBalance[row.id].cash.$numberDecimal).toFixed(2) +
                              currency.sign
                        : 0;
                },
            },
            {
                header: 'Credit',
                Cell: ({ row }) => {
                    return sessionBalance[row.id]
                        ? parseFloat(sessionBalance[row.id].credit.$numberDecimal).toFixed(2) +
                              currency.sign
                        : 0;
                },
            },
            {
                header: 'Total',
                Cell: ({ row }) => {
                    return sessionBalance[row.id]
                        ? (
                              parseFloat(sessionBalance[row.id].cash.$numberDecimal) +
                              parseFloat(sessionBalance[row.id].credit.$numberDecimal)
                          ).toFixed(2) + currency.sign
                        : 0;
                },
            },
        ],
        [sessionBalance, currency.sign]
    );

    const table = useMaterialReactTable({
        columns,

        data: rentalCategories,

        enableColumnOrdering: false,

        enableDensityToggle: false,

        enablePagination: false,

        enableRowActions: false,

        enableColumnFilters: false,

        enableSorting: true,

        enableColumnFilterModes: false,

        enableHiding: false,

        enableFullScreenToggle: true,

        enableColumnActions: false,

        enableBottomToolbar: false,

        positionToolbarDropZone: 'none',

        layoutMode: 'grid-no-grow',

        getRowId: (row) => row._id,

        renderDetailPanel: ({ row }) =>
            rentalArraysByCategories[row.id] ? (
                <RentalSessionsCategoryCards
                    rentals={rentalArraysByCategories[row.id]}
                    rentalCategory={row.original}
                    rentalSessions={rentalSessionsByCategory[row.id]}
                    onError={setError}
                    onSessionExpire={updateExpiredSessions}
                    ref={cardRefs}
                />
            ) : null,

        renderTopToolbarCustomActions: () => {
            return (
                <>
                    <div className="total-balance">
                        <div className="total-balance-item">
                            <label htmlFor="">
                                <LocalAtm className="paymentTypeIcon" />
                            </label>
                            <input
                                type="text"
                                disabled
                                title={
                                    'Sessions: ' +
                                    balance.sessionCash.toFixed(2) +
                                    '\r\nProducts: ' +
                                    balance.productCash.toFixed(2) +
                                    '\r\nRegistry: ' +
                                    balance.cashRegistry.toFixed(2) +
                                    '\r\n'
                                }
                                value={balance.totalCash.toFixed(2) + currency.sign}
                            />
                        </div>
                        <div className="total-balance-item">
                            <label htmlFor="">
                                <CreditCard className="paymentTypeIcon" />
                            </label>
                            <input
                                type="text"
                                disabled
                                title={
                                    'Sessions: ' +
                                    balance.sessionCredit.toFixed(2) +
                                    '\r\nProducts: ' +
                                    balance.productCredit.toFixed(2) +
                                    '\r\n'
                                }
                                value={balance.totalCredit.toFixed(2) + currency.sign}
                            />
                        </div>
                        <div className="total-balance-item">
                            <label htmlFor="">
                                <b>Total:</b>
                            </label>
                            <input
                                type="text"
                                disabled
                                title={
                                    'Sessions: ' +
                                    balance.sessionTotal.toFixed(2) +
                                    '\r\nProducts: ' +
                                    balance.productTotal.toFixed(2) +
                                    '\r\nRegistry: ' +
                                    balance.cashRegistry.toFixed(2) +
                                    '\r\n'
                                }
                                value={balance.total.toFixed(2) + currency.sign}
                            />
                        </div>
                    </div>
                    <div>
                        <IconButton
                            color="success"
                            className="refresh-balance-button"
                            onClick={invalidateSessionAndBalanceQueries}>
                            <Cached />
                        </IconButton>
                    </div>
                    <CashRegistry
                        cashRegistryBalance={balance.total.toFixed(2)}
                        setError={setError}
                    />
                </>
            );
        },

        muiTableBodyRowProps: {
            hover: false,
            sx: {
                // height: '6vh',
            },
        },

        muiTableContainerProps: {
            sx: {
                overflowY: 'scroll',
                height: '100vh',
            },
        },

        muiSearchTextFieldProps: {
            sx: {
                width: '10rem',
            },
        },

        state: {
            isLoading: isRentalCategoriesLoading || isRentalsLoading || isRentalSessionsLoading,
            showAlertBanner: isRentalCategoriesError || isRentalsError || isRentalSessionsError,
            showProgressBars:
                isRentalCategoriesFetching ||
                isRentalsFetching ||
                isRentalSessionsFetching ||
                isSessionBalanceFetching ||
                isProductSalesBalancePending ||
                isCashRegistryBalancePending,
        },

        initialState: {
            expanded: true,
        },
    });

    return (
        <>
            <MaterialReactTable table={table} />

            <ProductSales />
            <ExpiredAlert expiredSessions={expiredSessions} handleClick={handleAlertItemClick} />
            <TitleNotification
                notification={
                    Object.keys(expiredSessions).length
                        ? 'Sessions Expired (' + Object.keys(expiredSessions).length + ')'
                        : undefined
                }
            />

            <Alert message={error} setMessage={setError} />
        </>
    );
}

export default RentalSessions;

function useGetDailySessionBalance() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [RENTAL_SESSION_SALE_BALANCE_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get('/rentalSession/dailyBalance').then((result) => {
                if (result.status === 200) {
                    return result.data;
                }
            });
        },
        refetchInterval: 30 * 1000,
    });
}

function useGetDailyProductSaleBalance() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [PRODUCT_SALE_BALANCE_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get('/productSales/dailyBalance').then((result) => {
                if (result.status === 200) {
                    return result.data;
                }
            });
        },
        refetchInterval: 30 * 1000,
    });
}

function useGetDailyCashRegistryBalance() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [CASH_REGISTRY_BALANCE_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get('/organizationEvent/cashRegistry').then((result) => {
                if (result.status === 200) {
                    return result.data;
                }
            });
        },
        refetchInterval: 10 * 60 * 1000,
    });
}
