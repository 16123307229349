import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useCallback, useMemo, useState } from 'react';
import { useHttpClient } from '../../provider/HttpClientProvider';
import { useQuery } from '@tanstack/react-query';
import { BarChart } from '@mui/x-charts';
import dayjs from 'dayjs';
import { DASHBOARD_BAR_CHART_QUERY_KEY } from '../../shared/utils/queryConstants';
import { convertDateRangeToTimestamp } from '../../shared/utils/timestampUtils';
import { CHART_COLORS } from '../dashboardConstans';
import '../Dashboard.css';
import './BarChartWidget.css';

const emptyResult = [];
const LOADING_TEXT = 'LOADING';
const NO_STATS_TEXT = 'No Stats Available';
const LOADING_BARS = [{ [LOADING_TEXT]: 100, date: LOADING_TEXT }];
const NO_DATA_BARS = [{ [NO_STATS_TEXT]: 100, date: NO_STATS_TEXT }];

function BarChartWidget() {
    const [timestamp, setTimestamp] = useState({
        start: dayjs(new Date(new Date(Date.now() - 518400000).toDateString())),
        end: null,
    });

    const { data: incomes = emptyResult, isFetching: isIncomesFetching } = useGetIncomes(
        timestamp.start,
        timestamp.end
    );

    const chartDataSet = useMemo(() => {
        if (incomes.length) {
            return incomes.map((income) => {
                return income.categories.reduce(
                    (curCategories, category) => ({
                        ...curCategories,
                        [category.name]: parseFloat(category.sum.$numberDecimal),
                    }),
                    { date: income.date, Products: 0 }
                );
            });
        }

        return isIncomesFetching ? LOADING_BARS : NO_DATA_BARS;
    }, [incomes, isIncomesFetching]);

    const chartLabels = useMemo(() => {
        const categories = {};
        for (let data of chartDataSet) {
            for (let rentalCategory of Object.keys(data)) {
                categories[rentalCategory] = rentalCategory;
            }
        }

        delete categories.date;

        return Object.keys(categories);
    }, [chartDataSet]);

    const chartSeries = useMemo(() => {
        return chartLabels
            .map((label) => {
                return {
                    stackOrder: 'descending',
                    dataKey: label,
                    stack: label === 'Products' ? 'A' : 'B',
                    label: label,
                    ...(label === NO_STATS_TEXT
                        ? { color: 'var(--primary-red)' }
                        : label === LOADING_TEXT
                          ? { color: 'var(--primary-grey)' }
                          : {}),
                };
            })
            .reverse();
    }, [chartLabels]);

    const handleBarDatePick = useCallback(
        (value, targetedDate) => {
            setTimestamp((curDateRange) => ({ ...curDateRange, [targetedDate]: value }));
        },
        [setTimestamp]
    );
    return (
        <div className="dashboard-BarChart-wrapper">
            <h2 className="dashboard-chart-title">Income By Dates</h2>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                    <div className="dashboard-dataTimePickers-wrapper">
                        <DateTimePicker
                            name="startDate"
                            ampm={false}
                            label="Start Date"
                            value={timestamp.start}
                            onChange={(newValue) => handleBarDatePick(newValue, 'start')}
                            className="dashboard-dataTimePickers-item"
                            disableFuture={true}
                        />

                        <DateTimePicker
                            name="startDate"
                            ampm={false}
                            label="End Date"
                            value={timestamp.end}
                            onChange={(newValue) => handleBarDatePick(newValue, 'end')}
                            className="dashboard-dataTimePickers-item"
                            disableFuture={true}
                        />
                    </div>
                </DemoContainer>
            </LocalizationProvider>
            <BarChart
                dataset={chartDataSet}
                slotProps={{
                    axisTick: {
                        stroke: {
                            fill: 'white',
                        },
                    },
                    legend: {
                        itemGap: 20,
                        labelStyle: {
                            fontSize: '1.2rem',
                            fill: 'white',
                        },
                        direction: 'row',
                        position: { vertical: 'bottom', horizontal: 'middle' },
                        padding: -30,
                    },
                }}
                colors={CHART_COLORS}
                xAxis={[{ scaleType: 'band', dataKey: 'date' }]}
                series={chartSeries}
                axisHighlight={{ x: 'line', y: 'line' }}
                height={350}
            />
        </div>
    );
}

export default BarChartWidget;

function useGetIncomes(start, end) {
    const httpClient = useHttpClient();

    start = convertDateRangeToTimestamp(start?.$d);
    end = convertDateRangeToTimestamp(end?.$d);

    return useQuery({
        queryKey: [DASHBOARD_BAR_CHART_QUERY_KEY, start, end],
        queryFn: async () => {
            return await httpClient
                .get(`/stats/income?startTimestamp=${start}&endTimestamp=${end}&shouldGroup=true`)
                .then((result) => {
                    if (result.status === 200) {
                        let sessionData = result.data.sessionsSum;
                        for (let dateSessionSum of sessionData) {
                            for (let dateProductSum of result.data.productsSum) {
                                if (dateProductSum._id.date === dateSessionSum.date) {
                                    dateSessionSum.categories.push({
                                        name: dateProductSum.name,
                                        sum: dateProductSum.sum,
                                    });
                                }
                            }
                        }

                        return sessionData;
                    }
                });
        },
        refetchInterval: 5 * 60 * 1000,
    });
}
