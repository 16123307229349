import { useCallback, useMemo, useState } from 'react';
import { useHttpClient } from '../provider/HttpClientProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Backdrop, Box, Button, CircularProgress, Typography } from '@mui/material';
import Alert from '../shared/Alert/Alert';
import './ProductBulkStockControlTable.css';
import { PRODUCT_QUERY_KEY } from '../shared/utils/queryConstants';

// TODO Add error handling to every request
function ProductBulkStockControlTable({ products, setOpen }) {
    const [validationErrors, setValidationErrors] = useState({});
    const [error, setError] = useState();
    const [editedQunatities, setEditedQunatities] = useState({});

    //call UPDATE hook
    const { mutateAsync: updateQuantities, isPending: isUpdatingQuantities } =
        useUpdateProductQuantities(setError);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                enableEditing: false,
            },
            {
                header: 'Price',
                id: 'price',
                enableEditing: false,
                accessorFn: (dataRow) => parseFloat(dataRow?.price?.$numberDecimal),
            },
            {
                header: 'Quantity',
                id: 'quantity',
                accessorFn: () => 0,
                muiEditTextFieldProps: ({ cell, row }) => ({
                    type: 'number',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    onBlur: (event) => {
                        const value = event.currentTarget.value;

                        const validationError = !isNumeric(value)
                            ? 'Required whole number'
                            : undefined;
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });

                        setEditedQunatities((quantities) => ({
                            ...quantities,
                            [row.id]: {
                                ...row.original,
                                quantity: parseInt(value),
                            },
                        }));
                    },
                }),
            },
        ],
        [validationErrors]
    );

    const handleSaveQuantities = async () => {
        if (Object.values(validationErrors).some((error) => !!error)) return;

        if (await updateQuantities(Object.values(editedQunatities))) {
            setEditedQunatities({});
            setOpen(false);
        }
    };

    const table = useMaterialReactTable({
        columns,

        data: products,

        enableColumnOrdering: true,

        enableDensityToggle: false,

        enablePagination: false,

        enableEditing: true,

        enableBottomToolbar: false,

        positionActionsColumn: 'last',

        editDisplayMode: 'table',

        layoutMode: 'grid',

        getRowId: (row) => row._id,

        renderTopToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <Button
                    color="success"
                    variant="contained"
                    style={{ fontSize: '1.1em' }}
                    onClick={handleSaveQuantities}
                    disabled={
                        Object.keys(editedQunatities).length === 0 ||
                        Object.values(validationErrors).some((error) => !!error)
                    }>
                    {isUpdatingQuantities ? <CircularProgress size={25} /> : 'Save'}
                </Button>
                {Object.values(validationErrors).some((error) => !!error) && (
                    <Typography color="error">Fix errors before submitting</Typography>
                )}
            </Box>
        ),

        muiTableContainerProps: {
            sx: {
                overflowY: 'scroll',
                flex: 1,
            },
        },

        state: {
            isSaving: isUpdatingQuantities,
        },
    });

    return (
        <>
            <Backdrop className="recipe-backdrop" onClick={handleClose} open={true} />
            <div className="bulkTable-wrapper">
                <MaterialReactTable table={table} />
                <Alert message={error} setMessage={setError} />
            </div>
        </>
    );
}

//UPDATE hook (put product quantity in api)
function useUpdateProductQuantities(setError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (quantities) => {
            return await httpClient
                .put('/product/quantity/bulk', { products: quantities, operation: 'PLUS' })
                .then((result) => {
                    return true;
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [PRODUCT_QUERY_KEY] }),
    });
}

function isNumeric(value) {
    return /^-?\d+$/.test(value);
}

export default ProductBulkStockControlTable;
