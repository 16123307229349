export const CHART_COLORS = [
    '#fb8072',
    '#80b1d3',
    '#fdb462',
    '#bc80bd',
    '#ccebc5',
    '#ffed6f',
    '#8dd3c7',
    '#ffffb3',
    '#bebada',
    '#b3de69',
    '#fccde5',
    '#d9d9d9',
];
